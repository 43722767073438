<template>
  <div>
    <div
      class="wenben"
      :style="{
        background: `${data.style.bgColor}`,
        borderBottom: data.style.borderB ? '.5px solid #f5f5f5' : 'none',
      }"
    >
      <div :class="data.style.location">
        <div
          :style="{
            fontSize: `${data.style.titleFontSize}px`,
            fontWeight: `${data.style.titleWeight}`,
            color: `${data.style.titleColor}`,
          }"
        >
          {{ data.title }}213123
        </div>
        <div
          class="subhead"
          :style="{
            fontSize: `${data.style.subheadFontSize}px`,
            fontWeight: `${data.style.subheadWeight}`,
            color: `${data.style.subheadColor}`,
          }"
        >
          {{ data.subhead }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wenben",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  created() {
    console.log("显示组件内容：", this.data);
  },
  watch:{
    data: {
      handler(val) {
        console.log("change2：", val);
      },
      deep: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.wenben {
  width: 100%;
  min-height: 40px;
  padding: 15px 10px;
  word-break: break-all;
  .subhead {
    margin-top: 10px;
  }
}
</style>
