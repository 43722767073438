<template>
  <!-- <div :style="{ background: data.style.bgColor }"> -->
  <div>
    <el-carousel
      :autoplay="false"
      trigger="click"
      :indicator-position="indicator"
      height="400px"
    >
      <el-carousel-item v-for="(item, index) in urlList" :key="item">
        <div class="carouselItem">
          <el-image :src="item"> </el-image>
          <!-- 在这里插入自定义轮播点 -->
          <div class="carouselIndicator" v-show="indicator == 'none'">
            <span> {{ index + 1 }}/{{ urlList.length }}</span>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'shangpintu',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  watch: {
    //侦听样式
    'data.shape': {
      handler (newVal) {
        //样式改变
        this.shapeChange()
        //位置改变
        this.positionChange()
      },
      immediate: true
    },
    //侦听位置
    'data.position': {
      handler (newVal) {
        //样式改变
        this.shapeChange()
        //位置改变
        this.positionChange()
      },
      immediate: true
    },
    //侦听颜色
    'data.bgColor': {
      handler (newVal) {
        let bgc = newVal ? newVal : '#ffffff'
        document.documentElement.style.setProperty('--isActive-bgColor', bgc)
      },
      immediate: true
    }
  },
  data () {
    return {
      indicator: '',
      urlList: [
        'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-01/8b317b82d7b24534a36819bd6806d65b.png',
        'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-01/217b27aaefff475a85944f84ff1ac776.png',
        'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-01/b57ee3f0484748928546275666c631b0.png'
      ]
    }
  },
  methods: {
    //位置改变
    positionChange () {
      if (this.indicator == 'none') {
        let right = '45%'
        if (this.data.position == 'left') {
          right = '85%'
        } else if (this.data.position == 'center') {
          right = '45%'
        } else if (this.data.position == 'right') {
          right = '8%'
        }
        document.documentElement.style.setProperty('--num-right', right)
      } else {
        let right = 0
        if (this.data.position == 'left') {
          right = '70%'
        } else if (this.data.position == 'center') {
          right = '35%'
        } else if (this.data.position == 'right') {
          right = 0
        }
        document.documentElement.style.setProperty('--position-right', right)
      }
    },
    //样式改变
    shapeChange () {
      let width = 8
      let height = 8
      let borderRadius = 50
      this.indicator = ''
      if (this.data.shape == 'yuanjiao') {
        width = 10
        height = 4
        borderRadius = 30
      } else if (this.data.shape == 'yuanxing') {
        width = 8
        height = 8
        borderRadius = 50
      } else if (this.data.shape == 'shuzi') {
        this.indicator = 'none'
      }
      document.documentElement.style.setProperty('--style-width', `${width}px`)
      document.documentElement.style.setProperty(
        '--style-height',
        `${height}px`
      )
      document.documentElement.style.setProperty(
        '--style-borderRadius',
        `${borderRadius}%`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
//圆形
::v-deep .el-carousel__button {
  border: '1px solid gray';
  width: var(--style-width);
  height: var(--style-height);
  border-radius: var(--style-borderRadius);
}
//右边
::v-deep .el-carousel__indicators--horizontal {
  left: auto;
  right: var(--position-right);
}
::v-deep .el-carousel__indicator.is-active button {
  background-color: var(--isActive-bgColor); /*修改为你需要的颜色*/
}
//每一项图片
.carouselItem {
  position: relative;
  .carouselIndicator {
    left: auto;
    right: var(--num-right);
    bottom: 16px;
    background-color: var(--isActive-bgColor); /*修改为你需要的颜色*/
    padding: 3px;
    border-radius: 7px;
    position: absolute;
  }
}
</style>
