var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warp x-bc" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.data.isLayout === 0,
            expression: "data.isLayout === 0",
          },
        ],
        staticClass: "x-f",
      },
      [
        _c("iconpark-icon", {
          staticClass: "marL10 marR10",
          attrs: { name: "shouhuodizhi1", size: "18px" },
        }),
        _vm._m(0),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.data.isLayout === 1,
            expression: "data.isLayout === 1",
          },
        ],
        staticClass: "x-f",
      },
      [
        _c("iconpark-icon", {
          staticClass: "marL10 marR10",
          attrs: { name: "shouhuodizhi1", size: "18px" },
        }),
        _c("div", [
          _c("span", { staticClass: "fontS14 fontWeight7" }, [
            _vm._v("品牌名称"),
          ]),
          _c("br"),
          _c(
            "div",
            { staticClass: "x-f" },
            [
              _c("span", { staticClass: "fontS12 annotateGrey marR5" }, [
                _vm._v("{城市} {店铺名称}"),
              ]),
              _c("iconpark-icon", {
                staticClass: "annotateGrey",
                attrs: { name: "down", size: "18px" },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "switch" }, [
      _c("div", { staticClass: "receivingMethod1Btn" }, [
        _vm._v(_vm._s(_vm.data.receivingMethod1)),
      ]),
      _c("div", [_vm._v(_vm._s(_vm.data.receivingMethod2))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "fontS14 fontWeight7" }, [_vm._v("店铺名称")]),
      _c("br"),
      _c("span", { staticClass: "fontS12 annotateGrey" }, [
        _vm._v("距离您80m"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }