var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        margin:
          _vm.data.marT +
          "px " +
          _vm.data.marR +
          "px " +
          _vm.data.marB +
          "px " +
          _vm.data.marL +
          "px",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "warp",
          style: {
            borderRadius: _vm.data.borRadius + "px",
            backgroundColor: _vm.data.style.bgColor,
          },
        },
        _vm._l(_vm.data.contentList, function (item, index) {
          return _c("div", { key: index, staticClass: "x-bc line" }, [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("iconParkALi", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.isIcon,
                      expression: "data.isIcon",
                    },
                  ],
                  staticClass: "marR5",
                  attrs: {
                    name: item.leftIcon
                      ? item.leftIcon
                      : "icon-icon-morentubiao",
                    color: item.leftIcon ? item.style.iconColor : "",
                    size: "18px",
                  },
                }),
                _c("p", { style: { color: item.style.titleColor } }, [
                  _vm._v(_vm._s(item.title)),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.data.isSeeMore,
                    expression: "data.isSeeMore",
                  },
                ],
                staticClass: "x-f",
              },
              [
                _c(
                  "span",
                  { style: { color: _vm.data.style.seeMoreTitleColor } },
                  [_vm._v(_vm._s(_vm.data.seeMoreTitle))]
                ),
                _c("iconParkALi", {
                  attrs: {
                    name: _vm.data.seeMoreIcon,
                    size: "18px",
                    color: _vm.data.style.seeMoreIconColor,
                  },
                }),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }