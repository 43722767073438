<template>
    <div>
        <div class="im-box">
            <!-- 内容 -->
            <div :style="{
                margin:
                    data.style.marT +
                    'px ' +
                    data.style.marR +
                    'px ' +
                    data.style.marB +
                    'px ' +
                    data.style.marL +
                    'px'
            }">
                <!-- 内容 -->
                <div class="warp x-fc" :style="{
                    // borderRadius: data.style.borRadius + 'px',
                    overflow: 'hidden'
                    // backgroundColor: data.style.bgColor
                }">
                    <!--  -->
                    <div class="flex" v-if="data.style.type === '1'">
                        <div class="cube" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                            <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[0].url" fit="cover"
                                v-if="data.imgList[0]">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                        <div class="cube" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                            <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[1].url" fit="cover"
                                v-if="data.imgList[1]">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                    </div>
                    <!--  -->
                    <div class="flex" v-if="data.style.type === '2'">
                        <div class="cube-1" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                            <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[0].url" fit="cover">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                        <div class="cube-1" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                            <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[1].url" fit="cover"
                                v-if="data.imgList[1]">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                        <div class="cube-1" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                            <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[2].url" fit="cover"
                                v-if="data.imgList[2]">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                    </div>
                    <!--  -->
                    <div class="flex" v-if="data.style.type === '3'" style="overflow: hidden;">
                        <div class="cube dz" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                            <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[0].url" fit="cover">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                        <div class="cube-three">
                            <div class="cube-3" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                                <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[1].url" fit="cover"
                                    v-if="data.imgList[1]">
                                    <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                                </el-image>
                            </div>
                            <div class="cube-3" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                                <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[2].url" fit="cover"
                                    v-if="data.imgList[2]">
                                    <div slot="error" class="image-slot">
                                        <div>
                                            <i class="el-icon-plus"></i>
                                            <div>上传图片</div>
                                        </div>
                                    </div>
                                </el-image>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div v-if="data.style.type === '4'" style="overflow: hidden;">
                        <div class="cube tz" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                            <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[0].url" fit="cover">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                        <div class="cube-fro">
                            <div class="cube" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                                <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[1].url" fit="cover"
                                    v-if="data.imgList[1]">
                                    <div slot="error" class="image-slot">
                                        <div>
                                            <i class="el-icon-plus"></i>
                                            <div>上传图片</div>
                                        </div>
                                    </div>
                                </el-image>
                            </div>
                            <div class="cube" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                                <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[2].url" fit="cover"
                                    v-if="data.imgList[2]">
                                    <div slot="error" class="image-slot">
                                        <div>
                                            <i class="el-icon-plus"></i>
                                            <div>上传图片</div>
                                        </div>
                                    </div>
                                </el-image>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="flex" v-if="data.style.type === '5'" style="overflow: hidden">
                        <div class="cube dz" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                            <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[0].url" fit="cover">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                        <div class="cube-three">
                            <div class="cube-3" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                                <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[1].url" fit="cover"
                                    v-if="data.imgList[1]">
                                    <div slot="error" class="image-slot">
                                        <div>
                                            <i class="el-icon-plus"></i>
                                            <div>上传图片</div>
                                        </div>
                                    </div>
                                </el-image>
                            </div>
                            <div class="flex">
                                <div class="cube-2" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                                    <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[2].url" fit="cover"
                                        v-if="data.imgList[2]">
                                        <div slot="error" class="image-slot">
                                            <div>
                                                <i class="el-icon-plus"></i>
                                                <div>上传图片</div>
                                            </div>
                                        </div>
                                    </el-image>
                                </div>
                                <div class="cube-2" :style="{
                            margin: data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px ' + data.style.marO + 'px'
                        }">
                                    <el-image :style="{width: '100%', height: '100%',borderRadius: data.style.borRadius + 'px'}" :src="data.imgList[3].url" fit="cover"
                                        v-if="data.imgList[3]">
                                        <div slot="error" class="image-slot">
                                            <div>
                                                <i class="el-icon-plus"></i>
                                                <div>上传图片</div>
                                            </div>
                                        </div>
                                    </el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'anniuzu',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {}
    },
    created() {
        console.log('----data------', this.data);
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
//内容
// .warp {
//     height: 48px;
// }
.flex {
    display: flex
}

.cube {
    flex: 1;
    // width: 180px;
    height: 100px;
    // background-color: pink
}

.cube-1 {
    width: 120px;
    height: 120px;
    // background-color: pink
}

.dz {
    height: 360px
}

.tz {
    width: 360px
}

.cube-fro {
    display: flex;
}

.cube-2 {
    width: 90px;
    height: 180px;
    // background-color: pink
}
.cube-3 {
    width: 180px;
    height: 180px;
    // background-color: pink
}
.cube-three {

}
::v-deep .image-slot {
    text-align: center;
    margin-top: 30px;
}
</style>
