<template>
    <div>
        <div class="x-bc" :style="{background: data.style.bgColor}" style="padding: 10px">
           <div class="x-f">
               <el-image style="width: 60px;height: 60px" :src="data.style.imgUrl"></el-image>
               <div class="y-start marL10">
                   <span class="marB10 omit" style="font-size: 14px;font-weight: 550;width: 200px">{{data.style.title}}</span>
                   <span class="omit" style="font-size: 12px;color: #bababa;width: 200px">{{data.style.describe}}</span>
               </div>
           </div>
            <div style="padding: 5px;background: #ff0000;color: #FFFFFF;font-size: 12px;border-radius: 3px">{{data.style.btnName}}</div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'zhangfen',
    props:{
        data:{
            type: Object,
            default: {}
        }
    },
    computed:{
        // style(){
        //     let { style } = this.data
        //     let _style = {}
        //     _style.background = style.bgColor
        // }
    }
}
</script>

<style scoped>

</style>
