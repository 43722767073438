import { render, staticRenderFns } from "./anniuzu.vue?vue&type=template&id=6bac6f09&scoped=true"
import script from "./anniuzu.vue?vue&type=script&lang=js"
export * from "./anniuzu.vue?vue&type=script&lang=js"
import style0 from "./anniuzu.vue?vue&type=style&index=0&id=6bac6f09&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bac6f09",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-pre/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6bac6f09')) {
      api.createRecord('6bac6f09', component.options)
    } else {
      api.reload('6bac6f09', component.options)
    }
    module.hot.accept("./anniuzu.vue?vue&type=template&id=6bac6f09&scoped=true", function () {
      api.rerender('6bac6f09', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/O2OMall/config/template/decoratePage/components/conterTemplate/anniuzu.vue"
export default component.exports