<template>
  <div>
    <!-- 内容 -->
    <div
      :style="{
        margin:
          data.marT +
          'px ' +
          data.marR +
          'px ' +
          data.marB +
          'px ' +
          data.marL +
          'px'
      }"
    >
      <!-- 内容 -->
      <div
        class="warp x-fc"
        :style="{
          borderRadius: data.borRadius + 'px',
          backgroundColor: data.style.bgColor
        }"
      >
        <div
          class="flex1 x-fc"
          v-for="(item, index) in data.contentList"
          :key="index"
          :style="{
            borderRight:
              data.contentList.length - 1 > index
                ? '1px solid' + data.style.splitLineColor
                : ''
          }"
        >
          <iconParkALi
            v-show="data.isIcon"
            :name="item.icon ? item.icon : 'icon-morentubiao'"
            size="18px"
            :color="item.icon ? data.style.iconColor : ''"
            class="marR5"
          />
          <span
            class="fontS14"
            :style="{
              color: data.style.textColor
            }"
            >{{ item.title }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'anniuzu',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//内容
.warp {
  height: 48px;
}
</style>
