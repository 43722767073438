var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "padT5 padB5 x-f",
      style: {
        backgroundColor: _vm.data.contentStyle.bgColor,
        borderRadius:
          _vm.data.contentStyle.navShape === "suspension" ? "20px" : "0",
      },
    },
    [
      _vm._l(_vm.filteredArr, function (item, index) {
        return _c("div", { key: index, staticClass: "marL20" }, [
          _c(
            "div",
            {
              staticClass: "y-c",
              style:
                item.textInputList[0].value === "购物车"
                  ? { position: "relative" }
                  : {},
            },
            [
              _c("iconParkALi", {
                attrs: {
                  name: item.titleIconList[0].icon
                    ? item.titleIconList[0].icon
                    : "icon-morentubiao",
                  size: "18px",
                  color: item.titleIconList[0].icon
                    ? _vm.data.contentStyle.iconColor
                    : "",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "fontSW14 iconTitle",
                  style: {
                    color: _vm.data.contentStyle.textColor,
                  },
                },
                [_vm._v(_vm._s(item.textInputList[0].value) + " ")]
              ),
              item.textInputList[0].value === "购物车"
                ? _c(
                    "span",
                    {
                      staticClass: "badge",
                      style: {
                        backgroundColor: _vm.data.contentStyle.angleMarkColor,
                      },
                    },
                    [_vm._v(" 1 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      }),
      _c("div", { staticClass: "right" }, [
        _c(
          "span",
          {
            staticClass: "fontSW14 leftBtn",
            style: {
              backgroundColor: _vm.data.contentStyle.leftBtnColor,
            },
          },
          [_vm._v("加入购物车")]
        ),
        _c(
          "span",
          {
            staticClass: "fontSW14 rightBtn",
            style: {
              backgroundColor: _vm.data.contentStyle.rightBtnColor,
            },
          },
          [_vm._v("立即购买")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }