var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: {
          margin:
            _vm.data.marT +
            "px " +
            _vm.data.marR +
            "px " +
            _vm.data.marB +
            "px " +
            _vm.data.marL +
            "px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "warp",
            style: {
              borderRadius: _vm.data.borRadius + "px",
              backgroundColor: _vm.data.contentStyle.bgColor,
            },
          },
          [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c(
                  "span",
                  {
                    staticClass: "fontS16 marR5",
                    style: {
                      color: _vm.data.contentStyle.priceColor,
                    },
                  },
                  [_vm._v("￥166")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.isUnit,
                        expression: "data.isUnit",
                      },
                    ],
                    staticClass: "fontS14 marT5 marR5",
                    style: {
                      color: _vm.data.contentStyle.priceColor,
                    },
                  },
                  [_vm._v("/盒")]
                ),
                _c("span", { staticClass: "asterisk pricebgc" }, [
                  _vm._v("会员价"),
                ]),
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "marR10",
                    style: {
                      color: _vm.data.contentStyle.salesVolumeColor,
                    },
                  },
                  [_vm._v("库存999")]
                ),
                _c(
                  "span",
                  {
                    style: {
                      color: _vm.data.contentStyle.salesVolumeColor,
                    },
                  },
                  [_vm._v("销量666")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "originalPrice" }, [_vm._v("￥ 334")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.data.isMarketing,
                    expression: "data.isMarketing",
                  },
                ],
                staticClass: "x-bc marB15",
              },
              [
                _c("div", [
                  _c(
                    "span",
                    {
                      staticClass: "pricebgc marR5",
                      style: {
                        color: _vm.data.marketingStyle.textColor,
                        backgroundColor: _vm.data.marketingStyle.bgColor,
                      },
                    },
                    [_vm._v("券 | 满10减2")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "pricebgc marR5",
                      style: {
                        color: _vm.data.marketingStyle.textColor,
                        backgroundColor: _vm.data.marketingStyle.bgColor,
                      },
                    },
                    [_vm._v("满100减30")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "pricebgc marR5",
                      style: {
                        color: _vm.data.marketingStyle.textColor,
                        backgroundColor: _vm.data.marketingStyle.bgColor,
                      },
                    },
                    [_vm._v("满200包邮")]
                  ),
                ]),
                _c("div", [
                  _c(
                    "span",
                    {
                      style: {
                        color: _vm.data.marketingStyle.textColor,
                      },
                    },
                    [_vm._v("更多")]
                  ),
                  _c("i", {
                    staticClass: "el-icon-arrow-right",
                    style: {
                      color: _vm.data.marketingStyle.textColor,
                    },
                  }),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "goodsTitle marB10",
                style: {
                  color: _vm.data.contentStyle.titleColor,
                },
              },
              [
                _vm._v(
                  " 夏日清爽水果蛋糕女朋友生日蛋糕下午茶甜点生日聚会同城配送网红创意6寸 "
                ),
              ]
            ),
            _c("div", { staticClass: "x-bc" }, [
              _c(
                "span",
                {
                  style: {
                    color: _vm.data.contentStyle.subtitleColor,
                  },
                },
                [_vm._v(" 水果为主要材料，口感清新、爽口，适合夏季食用。")]
              ),
              _c("i", {
                staticClass: "el-icon-arrow-down",
                style: {
                  color: _vm.data.contentStyle.subtitleColor,
                },
              }),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.data.isService,
                    expression: "data.isService",
                  },
                ],
                staticClass: "service x-f",
              },
              [
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("iconpark-icon", {
                      staticClass: "marR5",
                      attrs: {
                        color: _vm.data.serviceStyle.labelColor,
                        name:
                          _vm.data.serviceStyle.shape == "duihao"
                            ? "check-one"
                            : "dot",
                      },
                    }),
                    _c(
                      "span",
                      {
                        style: {
                          color: _vm.data.serviceStyle.textColor,
                        },
                      },
                      [_vm._v("门店自提")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("iconpark-icon", {
                      staticClass: "marR5",
                      attrs: {
                        color: _vm.data.serviceStyle.labelColor,
                        name:
                          _vm.data.serviceStyle.shape == "duihao"
                            ? "check-one"
                            : "dot",
                      },
                    }),
                    _c(
                      "span",
                      {
                        style: {
                          color: _vm.data.serviceStyle.textColor,
                        },
                      },
                      [_vm._v("同城配送")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _c("iconpark-icon", {
                      staticClass: "marR5",
                      attrs: {
                        color: _vm.data.serviceStyle.labelColor,
                        name:
                          _vm.data.serviceStyle.shape == "duihao"
                            ? "check-one"
                            : "dot",
                      },
                    }),
                    _c(
                      "span",
                      {
                        style: {
                          color: _vm.data.serviceStyle.textColor,
                        },
                      },
                      [_vm._v("快递配送")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }