<template>
    <div>
        <div class="im-box" :style="{
            padding: data.type > 1 ? '40px 10px 0px 10px' : '',
            background: data.type === 2 ? `linear-gradient(to bottom, ${data.contentList[active].title} 0%, #f2f2f2 40%)` : '#fff',
        }">
        <div
        v-if="data.type === 3"
        :style="{
            backgroundColor: data.contentList[active].title,
            borderBottomLeftRadius: data.type === 3 ? '15px' : '',
            borderBottomRightRadius: data.type === 3 ? '15px' : '',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '200px',
            width: '100%',
        }">

        </div>
            <!-- 内容 -->
            <div :style="{
                margin:
                    data.marT +
                    'px ' +
                    data.marR +
                    'px ' +
                    data.marB +
                    'px ' +
                    data.marL +
                    'px',
                    overflow: 'hidden',
                    borderRadius: data.borRadius + 'px',
            }">
                <!-- 内容 -->
                <div class="warp x-fc" :style="{
                    borderRadius: data.borRadius + 'px',
                    // backgroundColor: data.style.bgColor
                }">
                    <!-- <div class="flex1 x-fc" v-for="(item, index) in data.contentList" :key="index">
                        <div>
                            <el-image :src="item.icon" fut="cover" />
                        </div>
                    </div> -->
                    <div class="carousel-container">
                        <div class="carousel-inner">
                            <el-carousel :height="carouselHeight" @change="changeImg"  v-if="this.data.isIcon">
                                    <el-carousel-item v-for="(item, index) in data.contentList" :key="index">
                                        <el-image :src="item.icon"  @load="event => updateCarouselHeight(event, index)" fit="cover" :style="{borderRadius: data.borRadius + 'px'}"/>
                                    </el-carousel-item>
                            </el-carousel>
                            <el-carousel :height="carouselHeight" @change="changeImg"  v-if="!this.data.isIcon">
                                    <el-carousel-item v-for="(item, index) in data.contentList" :key="index" :label="index + 1">
                                        <el-image :src="item.icon"  @load="event => updateCarouselHeight(event, index)" fit="cover" :style="{borderRadius: data.borRadius + 'px'}"/>
                                    </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'anniuzu',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            carouselHeight: '300px',
            bgColor: 0,
            active: 0,
            
        }
    },
    created() {
        console.log('----data------', this.data);
    },
    methods: {
        changeImg(v) {
            console.log('------v', v);
            this.active = v
        },
        updateCarouselHeight(event, index) {
            if (index === 0) {
                this.carouselHeight = event.target.height + 'px';
                this.data.height = event.target.height
            }
        }
    }
}
</script>

<style lang="scss" scoped>
//内容
// .warp {
//     height: 48px;
// }
.im-box {
    // padding: 40px 10px 0px 10px;
}
.custom-indicator {
  margin: 5px;
  background-color: #909399;
  color: #fff;
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
}
 
.custom-indicator.is-active {
  background-color: #409EFF;
}
// ::v-deep .el-carousel__container {
//     max-height: 625px;
// }
.carousel-container {
    width: 100%;
}

.carousel-inner {
    width: 100%;
}

el-carousel {
    width: 100%;
    display: block;
}

el-image {
    display: block;
    width: 100%;
}
</style>
