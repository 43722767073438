var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.style.templateNo == 1
      ? _c(
          "div",
          {
            staticClass: "item",
            style: [
              { paddingLeft: _vm.data.style.pagePadding + "px" },
              { paddingRight: _vm.data.style.pagePadding + "px" },
              {
                backgroundColor:
                  _vm.data.style.cardStyle == 2 ? _vm.data.style.bgColor : "",
              },
              {
                backgroundColor:
                  _vm.data.style.cardStyle == 3 ? _vm.data.style.bgColor : "",
              },
              {
                margin:
                  _vm.data.marT +
                  "px " +
                  _vm.data.marR +
                  "px " +
                  _vm.data.marB +
                  "px " +
                  _vm.data.marL +
                  "px",
              },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "x-bc",
                staticStyle: { padding: "10px" },
                style: [
                  {
                    backgroundColor:
                      _vm.data.style.cardStyle == 4
                        ? "rgba(255,255,255,0)"
                        : _vm.data.style.cardBgColor,
                  },
                  { borderRadius: _vm.data.borRadius + "px" },
                  {
                    boxShadow:
                      _vm.data.style.cardStyle == 2
                        ? "-3px 3px 3px rgba(0,0,0,0.2), 3px 0px 3px rgba(0,0,0,0.2)"
                        : "",
                  },
                  {
                    border:
                      _vm.data.style.cardStyle == 3 ? "1px solid #e9e9e9" : "",
                  },
                  {
                    backgroundImage:
                      _vm.data.style.bgStyle === "image"
                        ? "url(" + _vm.data.style.bgImage + ")"
                        : "",
                  },
                  { backgroundSize: "cover" },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _vm.data.style.isAvatar
                      ? _c("el-avatar", {
                          attrs: { src: _vm.data.style.avatar, size: 50 },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "y-start marL10" }, [
                      _c(
                        "span",
                        {
                          staticClass: "fontS14",
                          style: { color: _vm.data.style.textColor },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.data.style.titleName) +
                              _vm._s(_vm.data.style.descriptionText) +
                              _vm._s(
                                _vm.data.style.descriptionText == "余额"
                                  ? "￥0"
                                  : "(0)"
                              )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "x-f",
                          staticStyle: { color: "#bababa" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.data.style.propertyMsg) + " "
                          ),
                          _c("i", {
                            staticClass: "el-icon-arrow-right",
                            staticStyle: { "margin-left": "5px" },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm.data.style.isVipQrCode
                  ? _c(
                      "div",
                      { staticClass: "y-f marR10 fontS12" },
                      [
                        _c("iconpark-icon", {
                          attrs: {
                            name: "two-dimensional-code-two",
                            size: "20px",
                          },
                        }),
                        _c("span", { staticStyle: { color: "#bababa" } }, [
                          _vm._v("会员码"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.data.style.templateNo == 2 ? _c("div", [_vm._v(" sadasd ")]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }