<template>
    <div>
        <div class="item" v-if="data.style.templateNo == 1" :style="[
            { paddingLeft: data.style.pagePadding + 'px' },
            { paddingRight: data.style.pagePadding + 'px' },
            { backgroundColor: data.style.cardStyle == 2 ? data.style.bgColor : '' },
            { backgroundColor: data.style.cardStyle == 3 ? data.style.bgColor : '' },
            {
                margin:
                    data.marT +
                    'px ' +
                    data.marR +
                    'px ' +
                    data.marB +
                    'px ' +
                    data.marL +
                    'px'
            }
        ]">
            <div :style="[
                { backgroundColor: data.style.cardStyle == 4 ? 'rgba(255,255,255,0)' : data.style.cardBgColor },
                { borderRadius: data.borRadius + 'px' },
                { boxShadow: data.style.cardStyle == 2 ? '-3px 3px 3px rgba(0,0,0,0.2), 3px 0px 3px rgba(0,0,0,0.2)' : '' },
                { border: data.style.cardStyle == 3 ? '1px solid #e9e9e9' : '' },
                { backgroundImage: data.style.bgStyle === 'image' ? `url(${data.style.bgImage})` : '', },
                { backgroundSize: 'cover' }
            ]" class="x-bc" style="padding: 10px">
                <div class="x-f">
                    <el-avatar v-if="data.style.isAvatar" :src="data.style.avatar" :size="50"></el-avatar>
                    <div class="y-start marL10">
                        <span class="fontS14"
                            :style="{ color: data.style.textColor }">{{ data.style.titleName }}{{ data.style.descriptionText }}{{ data.style.descriptionText == '余额'? '￥0' : '(0)' }}</span>
                        <div class="x-f" style="color: #bababa">
                            {{ data.style.propertyMsg }}
                            <i class="el-icon-arrow-right" style="margin-left: 5px"></i>
                        </div>
                    </div>
                </div>
                <div class="y-f marR10 fontS12" v-if="data.style.isVipQrCode">
                    <iconpark-icon name="two-dimensional-code-two" size="20px"></iconpark-icon>
                    <span style="color: #bababa">会员码</span>
                </div>
            </div>
        </div>
        <div v-if="data.style.templateNo == 2">
            sadasd
        </div>
    </div>
</template>

<script>
export default {
    name: 'zichan',
    props: {
        data: {
            type: Object,
            default: {}
        },
    },
    computed: {
        // style(){
        //     let { style } = this.data
        //     let _style = {}
        //     // _style.background = `${style.bgColor}px`
        //     _style.paddingLeft = `${style.pagePadding}px`
        //     _style.paddingRight = `${style.pagePadding}px`
        //     if(style.cardStyle == 2){
        //         _style.backgroundColor = `${style.bgColor}`
        //     } else if(style.cardStyle == 3){
        //         _style.backgroundColor = `${style.bgColor}`
        //     }
        //     return _style
        // },
        interiorStyle() {
            let { style } = this.data
            let _style = {}
            _style.backgroundColor = `${style.cardBgColor}`
            _style.borderRadius = `${style.chamfer}px`
            _style.padding = '10px'
            // _style.borderRadius = `${style.chamfer}px`
            console.log('卡片样式1', style.cardStyle)
            if (style.cardStyle == 2) {
                _style.boxShadow = '-3px 3px 3px rgba(0,0,0,0.2), 3px 0px 3px rgba(0,0,0,0.2)'
            } else if (style.cardStyle == 3) {
                _style.border = '1px solid #e9e9e9'
            } else if (style.cardStyle == 4) {
                _style.backgroundColor = `rgba(255,255,255,0)`
            }
            return _style
        }
    },
    methods: {

    }
}
</script>

<style scoped></style>
