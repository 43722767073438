var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: {
          margin:
            _vm.data.marT +
            "px " +
            _vm.data.marR +
            "px " +
            _vm.data.marB +
            "px " +
            _vm.data.marL +
            "px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "warp x-bc",
            class: _vm.data.titlePosition == "left" ? "x-bc" : "x-c",
            style: {
              borderRadius: _vm.data.borRadius + "px",
              backgroundColor:
                _vm.data.style.bgColorType === "bgc"
                  ? _vm.data.style.bgColor
                  : "",
            },
          },
          [
            _c(
              "div",
              {
                class:
                  _vm.data.isAssistantEnable &&
                  _vm.data.assistantSetType === "leftRight"
                    ? "xF"
                    : "yStart",
              },
              [
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _c("iconParkALi", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.data.titleStyle.titleType === "icon",
                          expression: "data.titleStyle.titleType === 'icon'",
                        },
                      ],
                      staticClass: "marR3",
                      attrs: {
                        name: _vm.data.titleIcon
                          ? _vm.data.titleIcon
                          : "icon-morentubiao",
                        size: "18px",
                        color: _vm.data.titleStyle.textColor,
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "fontWeight7",
                        style: {
                          color: _vm.data.titleStyle.textColor,
                          fontSize: _vm.data.titleStyle.fontSize + "px",
                          borderBottom:
                            _vm.data.titleStyle.titleType === "optionsBar"
                              ? "4px" +
                                " solid" +
                                _vm.data.titleStyle.underlineColor
                              : "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.data.title))]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.isAssistantEnable,
                        expression: "data.isAssistantEnable",
                      },
                    ],
                    style: {
                      color: _vm.data.assistantStyle.textColor,
                      fontSize: _vm.data.assistantStyle.fontSize + "px",
                      marginLeft:
                        _vm.data.assistantSetType === "leftRight" ? "5px" : "",
                      marginTop:
                        _vm.data.assistantSetType === "topBottom" ? "5px" : "",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.data.assistantTitle) + " ")]
                ),
              ]
            ),
            _c(
              "div",
              {
                style: {
                  position: "absolute",
                  right: "15px",
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.isSeeMore,
                        expression: "data.isSeeMore",
                      },
                    ],
                    staticClass: "x-f",
                  },
                  [
                    _c(
                      "span",
                      {
                        style: {
                          fontSize: _vm.data.seeMoreStyle.fontSize + "px",
                          color: _vm.data.seeMoreStyle.textColor,
                        },
                      },
                      [_vm._v(_vm._s(_vm.data.seeMoreTitle))]
                    ),
                    _c("iconParkALi", {
                      attrs: {
                        name: _vm.data.seeMoreIcon,
                        size: "18px",
                        color: _vm.data.seeMoreStyle.textColor,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }