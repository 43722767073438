<template>
  <div
    v-if="newData"
    :class="['mofang', `mofang-type${newData.style.type}`]"
    :style="
      edit
        ? ''
        : `margin-top:${newData.style.marT}px;margin-bottom:${
            newData.style.marB
          }px;margin-left:${newData.style.marL}px;margin-right:${
            newData.style.marR
          }px;width: ${width - newData.style.marL - newData.style.marR}px;`
    "
  >
    <template v-if="newData.style.type === 1">
      <el-image
        v-for="(item, index) in newData.imgList"
        :key="index"
        :src="item.image"
        @click="toClick(index, item)"
        :style="item.imgStyle"
        mode="scaleToFill"
      >
        <div slot="error" class="x-c" style="height: 100%" @click="toClick(index, item)">
          <p v-if="edit" class="text">建议宽度 375px</p>
          <i
            v-else
            class="el-icon-picture-outline"
            style="font-size: 40px; color: #ababab"
          />
        </div>
      </el-image>
    </template>
    <template v-else-if="newData.style.type === 2">
      <el-image
        v-for="(item, index) in newData.imgList.slice(0, 1)"
        :key="index"
        :src="item.image"
        @click="toClick(index, item)"
        :style="item.imgStyle + (edit ? '' : `argin-right: ${newData.style.marN / 2}`)"
        mode="scaleToFill"
      >
        <div slot="error" class="x-c" style="height: 100%" @click="toClick(index, item)">
          <p v-if="edit" class="text">建议宽高 375 * 375px</p>
          <i
            v-else
            class="el-icon-picture-outline"
            style="font-size: 40px; color: #ababab"
          />
        </div>
      </el-image>
      <div
        :style="
          newData.imgList[0].imgStyle +
          (edit ? '' : `margin-left: ${newData.style.marN / 2}px;`)
        "
      >
        <el-image
          v-for="(item, index) in newData.imgList.slice(1)"
          :key="index"
          :src="item.image"
          @click="toClick(index + 1, item)"
          :style="item.imgStyle"
          mode="scaleToFill"
        >
          <div
            slot="error"
            class="x-c"
            style="height: 100%"
            @click="toClick(index + 1, item)"
          >
            <p v-if="edit" class="text">建议宽高 375 * 375px</p>
            <i
              v-else
              class="el-icon-picture-outline"
              style="font-size: 40px; color: #ababab"
            />
          </div>
        </el-image>
      </div>
    </template>
  </div>
</template>

<script>
import { getENVIR } from "@/utils/index";
export default {
  name: "mofang",
  props: {
    data: {
      type: Object,
      default: {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: 0,
    };
  },
  computed: {
    newData() {
      this.width = this.edit ? 361 : 371;
      try {
        this.width = uni.getSystemInfoSync().screenWidth;
      } catch (error) {}
      return {
        ...this.data,
        imgList: this.data.imgList.map((item, i) => {
          const obj = item;
          let imgStyle = "";
          const { marL, marR, marB, marT, marN, borRadius, type } = this.data.style;
          const { minScale } = item;
          switch (type) {
            case 1:
              if (this.edit) {
                const width = this.width / 2;
                imgStyle = `overflow: hidden; width: ${width}px; height: ${
                  width / minScale
                }px;`;
              } else {
                const width = (this.width - marL - marR) / 2 - marN / 2;
                imgStyle = `overflow: hidden; width: ${width}px; height: ${
                  width / minScale
                }px; border-radius: ${borRadius}px; ${
                  i === 0 ? "margin-right" : "margin-left"
                }: ${marN / 2}px;`;
              }
              break;
            case 2:
              {
                if (this.edit) {
                  const width = this.width / 2;
                  imgStyle =
                    i === 0
                      ? `overflow: hidden; width: ${width}px; height: ${width * 2}px`
                      : `width: 100%; height: 50%;overflow: hidden;`;
                } else {
                  const width = (this.width - marL - marR) / 2 - marN / 2;
                  imgStyle =
                    i === 0
                      ? `overflow: hidden; width: ${width - marN / 2}px; height: ${
                          width - marN / 2
                        }px; border-radius: ${borRadius}px;`
                      : `overflow: hidden; width: 100%; height: calc(50% - ${
                          marN / 2
                        }px);margin-${i === 1 ? "bottom" : "top"}: ${
                          marN / 2
                        }px; border-radius: ${borRadius}px;`;
                }
              }
              break;
            default:
              break;
          }
          return { ...obj, imgStyle };
        }),
      };
    },
  },
  methods: {
    toClick(e, item) {
      if (this.edit) {
        this.$emit("change", e);
      } else {
        try {
          this.$t.adminUrlJump(item.link?.url);
        } catch (error) {}
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mofang {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  .text {
    margin: 0 !important;
  }
}
</style>
