<template>
  <!-- 内容 -->
  <div
    :style="{
      margin:
        data.marT +
        'px ' +
        data.marR +
        'px ' +
        data.marB +
        'px ' +
        data.marL +
        'px'
    }"
  >
    <!-- 内容 -->
    <div
      class="warp"
      :style="{
        borderRadius: data.borRadius + 'px',
        backgroundColor: data.style.bgColor
      }"
    >
      <div
        v-for="(item, index) in data.contentList"
        :key="index"
        class="x-bc line"
      >
        <!-- 左边 -->
        <div class="x-f">
          <iconParkALi
            v-show="data.isIcon"
            :name="item.leftIcon ? item.leftIcon : 'icon-icon-morentubiao'"
            :color="item.leftIcon ? item.style.iconColor : ''"
            size="18px"
            class="marR5"
          />
          <p :style="{ color: item.style.titleColor }">{{ item.title }}</p>
        </div>
        <!-- 右边 -->
        <div v-show="data.isSeeMore" class="x-f">
          <span :style="{ color: data.style.seeMoreTitleColor }">{{
            data.seeMoreTitle
          }}</span>
          <iconParkALi
            :name="data.seeMoreIcon"
            size="18px"
            :color="data.style.seeMoreIconColor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'liebiaodaohang',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//内容
.warp {
  padding: 15px 10px;
  //每一行
  .line {
    height: 40px;
    // line-height: 48px;
    p {
      padding: 0;
      margin: 0;
    }
  }
  /* x水平排列*/
  .xF {
    display: flex;
    align-items: center;
  }
  /*y竖直靠左*/
  .yStart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
