var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: {
          margin:
            _vm.data.marT +
            "px " +
            _vm.data.marR +
            "px " +
            _vm.data.marB +
            "px " +
            _vm.data.marL +
            "px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "warp x-fc",
            style: {
              borderRadius: _vm.data.borRadius + "px",
              backgroundColor: _vm.data.style.bgColor,
            },
          },
          _vm._l(_vm.data.contentList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "flex1 x-fc",
                style: {
                  borderRight:
                    _vm.data.contentList.length - 1 > index
                      ? "1px solid" + _vm.data.style.splitLineColor
                      : "",
                },
              },
              [
                _c("iconParkALi", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.isIcon,
                      expression: "data.isIcon",
                    },
                  ],
                  staticClass: "marR5",
                  attrs: {
                    name: item.icon ? item.icon : "icon-morentubiao",
                    size: "18px",
                    color: item.icon ? _vm.data.style.iconColor : "",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "fontS14",
                    style: {
                      color: _vm.data.style.textColor,
                    },
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }