var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.styleObj.curListStyleIndex > 0
      ? _c(
          "div",
          {
            staticClass: "item y-c padd10",
            class: _vm.cardList[_vm.styleObj.curCardStyleIndex].style,
            style: [
              { color: _vm.styleObj.textColor },
              { backgroundColor: _vm.styleObj.couponColor },
              {
                border:
                  _vm.styleObj.curListStyleIndex == 3
                    ? "5px solid " + _vm.styleObj.couponColor
                    : "",
              },
              {
                borderRadius: _vm.styleObj.curCardStyleIndex == 3 ? "5px" : "",
              },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "y-c",
                staticStyle: { width: "100%" },
                style: _vm.style2,
              },
              [
                _c("div", { staticClass: "x-f" }, [
                  _c("span", { staticClass: "fontS25" }, [
                    _vm._v(_vm._s(_vm.data.parValue)),
                  ]),
                  _c("span", [_vm._v("元")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "x-c",
                    class: { omit: _vm.styleObj.curListStyleIndex > 0 },
                    staticStyle: { width: "100%", "box-sizing": "border-box" },
                  },
                  [_vm._v(" " + _vm._s(_vm.data.couponDesc) + " ")]
                ),
              ]
            ),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "x-bc item marB10",
            class: [
              _vm.cardList[_vm.styleObj.curCardStyleIndex].style,
              { border: _vm.style.curCardStyleIndex == 4 },
            ],
            style: [
              { color: _vm.styleObj.textColor },
              { backgroundColor: "" + _vm.styleObj.couponColor },
              {
                border:
                  _vm.styleObj.curListStyleIndex == 3
                    ? "5px solid " + _vm.styleObj.couponColor
                    : "",
              },
              { border: _vm.styleObj.curCardStyleIndex == 4 ? "5px" : "" },
              {
                borderRadius: _vm.styleObj.curCardStyleIndex == 3 ? "5px" : "",
              },
            ],
          },
          [
            _c("div", { staticClass: "x-f", staticStyle: { width: "70%" } }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "fontS25" }, [
                  _vm._v(_vm._s(_vm.data.parValue)),
                ]),
                _c("span", [_vm._v("元")]),
              ]),
              _c("div", { staticClass: "y-start marL10" }, [
                _c("div", [_vm._v(_vm._s(_vm.data.couponCaseName))]),
                _c(
                  "div",
                  {
                    staticClass: "x-c",
                    class: { omit: _vm.styleObj.curListStyleIndex > 0 },
                    staticStyle: { width: "100%", "box-sizing": "border-box" },
                  },
                  [_vm._v(" " + _vm._s(_vm.data.couponDesc) + " ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "x-c", staticStyle: { width: "30%" } }, [
              _vm._v(" 领取 "),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }