var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { float: "right" } }, [
    _c(
      "div",
      { staticClass: "box" },
      _vm._l(_vm.data.immageNavList, function (item, index) {
        return _c("div", { key: index, staticClass: "content" }, [
          _c("img", { attrs: { src: item.imgUrl, alt: "" } }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }