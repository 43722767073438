var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goods" }, [
    _c(
      "div",
      {
        staticClass: "goods-list",
        style: {
          paddingLeft: _vm.data.moreSet.pageBackGauge + "px",
          paddingRight: _vm.data.moreSet.pageBackGauge + "px",
        },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.curListStyleIndex === 0,
                expression: "data.curListStyleIndex === 0",
              },
            ],
            staticClass: "list",
          },
          _vm._l(
            _vm.data.goodList.length < 1
              ? _vm.data.temporaryGoodList
              : _vm.data.goodList,
            function (item, index) {
              return _c(
                "TimeLimitActivity",
                {
                  key: index,
                  style: {
                    width: "100%",
                    marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                    marginBottom: _vm.data.moreSet.goodsBackGauge + "px",
                  },
                  attrs: {
                    overallFlex: true,
                    photoWidthHeight: _vm.data.photoWidthHeight,
                    goodsItem: item,
                    showActivityTime: true,
                    streamer: "12",
                    infoHeight: "155px",
                    curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                    moreSet: _vm.data.moreSet,
                    CardIndex: _vm.data.curShoppingCardIndex,
                    CardText: _vm.data.btnText,
                  },
                },
                [_vm._v(_vm._s(item.goodsName))]
              )
            }
          ),
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.curListStyleIndex === 1,
                expression: "data.curListStyleIndex === 1",
              },
            ],
            staticClass: "small x-bc",
          },
          _vm._l(
            _vm.data.goodList < 1
              ? _vm.data.temporaryGoodList
              : _vm.data.goodList,
            function (item, index) {
              return _c("TimeLimitActivity", {
                key: index,
                style: {
                  width:
                    "calc(50% - " + _vm.data.moreSet.goodsBackGauge + "px)",
                  marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                },
                attrs: {
                  photoWidthHeight: _vm.data.photoWidthHeight,
                  goodsItem: item,
                  showActivityTime: true,
                  streamer: "12",
                  curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                  moreSet: _vm.data.moreSet,
                  CardIndex: _vm.data.curShoppingCardIndex,
                  CardText: _vm.data.btnText,
                  goodsRatio: _vm.data.moreSet.goodsRatio,
                },
              })
            }
          ),
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.curListStyleIndex === 2,
                expression: "data.curListStyleIndex === 2",
              },
            ],
            staticClass: "three x-bc",
          },
          _vm._l(
            _vm.data.goodList < 1
              ? _vm.data.temporaryGoodList
              : _vm.data.goodList,
            function (item, index) {
              return _c("TimeLimitActivity", {
                key: index,
                style: {
                  width:
                    "calc(33.33% - " + _vm.data.moreSet.goodsBackGauge + "px)",
                },
                attrs: {
                  photoWidthHeight: _vm.data.photoWidthHeight,
                  goodsItem: item,
                  showActivityTime: false,
                  streamer: "12",
                  curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                  moreSet: _vm.data.moreSet,
                  CardIndex: _vm.data.curShoppingCardIndex,
                  CardText: _vm.data.btnText,
                  goodsRatio: _vm.data.moreSet.goodsRatio,
                },
              })
            }
          ),
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.curListStyleIndex === 3,
                expression: "data.curListStyleIndex === 3",
              },
            ],
            staticClass: "big",
          },
          _vm._l(
            _vm.data.goodList < 1
              ? _vm.data.temporaryGoodList
              : _vm.data.goodList,
            function (item, index) {
              return _c("TimeLimitActivity", {
                key: index,
                style: {
                  width:
                    "calc(100% - " + _vm.data.moreSet.goodsBackGauge + "px)",
                  marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                },
                attrs: {
                  photoWidthHeight: _vm.data.photoWidthHeight,
                  goodsItem: item,
                  showActivityTime: true,
                  streamer: "15",
                  curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                  moreSet: _vm.data.moreSet,
                  CardIndex: _vm.data.curShoppingCardIndex,
                  CardText: _vm.data.btnText,
                  goodsRatio: _vm.data.moreSet.goodsRatio,
                },
              })
            }
          ),
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.curListStyleIndex === 4,
                expression: "data.curListStyleIndex === 4",
              },
            ],
            staticClass: "swipe x-bc",
          },
          [
            _c(
              "el-scrollbar",
              _vm._l(
                _vm.data.goodList < 1
                  ? _vm.data.temporaryGoodList
                  : _vm.data.goodList,
                function (item, index) {
                  return _c("TimeLimitActivity", {
                    key: index,
                    style: {
                      width:
                        "calc(100% - " +
                        _vm.data.moreSet.goodsBackGauge +
                        "px)",
                      marginRight: _vm.data.moreSet.goodsBackGauge + "px",
                    },
                    attrs: {
                      photoWidthHeight: _vm.data.photoWidthHeight,
                      goodsItem: item,
                      showActivityTime: false,
                      curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                      moreSet: _vm.data.moreSet,
                      CardIndex: _vm.data.curShoppingCardIndex,
                      CardText: _vm.data.btnText,
                      goodsRatio: _vm.data.moreSet.goodsRatio,
                    },
                  })
                }
              ),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.curListStyleIndex === 5,
                expression: "data.curListStyleIndex === 5",
              },
            ],
            staticClass: "hybrid",
          },
          [
            _c(
              "div",
              { staticClass: "one" },
              [
                _c("TimeLimitActivity", {
                  style: {
                    width: "100%",
                    marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                  },
                  attrs: {
                    photoWidthHeight: _vm.data.photoWidthHeight,
                    curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                    moreSet: _vm.data.moreSet,
                    CardIndex: _vm.data.curShoppingCardIndex,
                    CardText: _vm.data.btnText,
                    showActivityTime: true,
                    goodsItem: _vm.data.singleGood,
                    streamer: "15",
                    goodsRatio: _vm.data.moreSet.goodsRatio,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "x-bc" },
              _vm._l(_vm.goods, function (item, index) {
                return _c("TimeLimitActivity", {
                  key: index,
                  style: {
                    width:
                      "calc(50% - " + _vm.data.moreSet.goodsBackGauge + "px)",
                    marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                  },
                  attrs: {
                    photoWidthHeight: _vm.data.photoWidthHeightTwo,
                    goodsItem: item,
                    showActivityTime: true,
                    lineFeed: true,
                    streamer: "12",
                    curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                    moreSet: _vm.data.moreSet,
                    CardIndex: _vm.data.curShoppingCardIndex,
                    CardText: _vm.data.btnText,
                    goodsRatio: _vm.data.moreSet.goodsRatio,
                  },
                })
              }),
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }