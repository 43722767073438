import { render, staticRenderFns } from "./shangpintu.vue?vue&type=template&id=1714ae60&scoped=true"
import script from "./shangpintu.vue?vue&type=script&lang=js"
export * from "./shangpintu.vue?vue&type=script&lang=js"
import style0 from "./shangpintu.vue?vue&type=style&index=0&id=1714ae60&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1714ae60",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-pre/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1714ae60')) {
      api.createRecord('1714ae60', component.options)
    } else {
      api.reload('1714ae60', component.options)
    }
    module.hot.accept("./shangpintu.vue?vue&type=template&id=1714ae60&scoped=true", function () {
      api.rerender('1714ae60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/O2OMall/config/template/decoratePage/components/conterTemplate/shangpintu.vue"
export default component.exports