var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "im-box" }, [
      _c(
        "div",
        {
          style: {
            margin:
              _vm.data.style.marT +
              "px " +
              _vm.data.style.marR +
              "px " +
              _vm.data.style.marB +
              "px " +
              _vm.data.style.marL +
              "px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "warp x-fc",
              style: {
                // borderRadius: data.style.borRadius + 'px',
                overflow: "hidden",
                // backgroundColor: data.style.bgColor
              },
            },
            [
              _vm.data.style.type === "1"
                ? _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cube",
                        style: {
                          margin:
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px",
                        },
                      },
                      [
                        _vm.data.imgList[0]
                          ? _c(
                              "el-image",
                              {
                                style: {
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: _vm.data.style.borRadius + "px",
                                },
                                attrs: {
                                  src: _vm.data.imgList[0].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("div", [
                                      _c("i", { staticClass: "el-icon-plus" }),
                                      _c("div", [_vm._v("上传图片")]),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "cube",
                        style: {
                          margin:
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px",
                        },
                      },
                      [
                        _vm.data.imgList[1]
                          ? _c(
                              "el-image",
                              {
                                style: {
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: _vm.data.style.borRadius + "px",
                                },
                                attrs: {
                                  src: _vm.data.imgList[1].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("div", [
                                      _c("i", { staticClass: "el-icon-plus" }),
                                      _c("div", [_vm._v("上传图片")]),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.data.style.type === "2"
                ? _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cube-1",
                        style: {
                          margin:
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px",
                        },
                      },
                      [
                        _c(
                          "el-image",
                          {
                            style: {
                              width: "100%",
                              height: "100%",
                              borderRadius: _vm.data.style.borRadius + "px",
                            },
                            attrs: {
                              src: _vm.data.imgList[0].url,
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("div", [_vm._v("上传图片")]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "cube-1",
                        style: {
                          margin:
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px",
                        },
                      },
                      [
                        _vm.data.imgList[1]
                          ? _c(
                              "el-image",
                              {
                                style: {
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: _vm.data.style.borRadius + "px",
                                },
                                attrs: {
                                  src: _vm.data.imgList[1].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("div", [
                                      _c("i", { staticClass: "el-icon-plus" }),
                                      _c("div", [_vm._v("上传图片")]),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "cube-1",
                        style: {
                          margin:
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px",
                        },
                      },
                      [
                        _vm.data.imgList[2]
                          ? _c(
                              "el-image",
                              {
                                style: {
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: _vm.data.style.borRadius + "px",
                                },
                                attrs: {
                                  src: _vm.data.imgList[2].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("div", [
                                      _c("i", { staticClass: "el-icon-plus" }),
                                      _c("div", [_vm._v("上传图片")]),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.data.style.type === "3"
                ? _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { overflow: "hidden" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cube dz",
                          style: {
                            margin:
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px",
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              style: {
                                width: "100%",
                                height: "100%",
                                borderRadius: _vm.data.style.borRadius + "px",
                              },
                              attrs: {
                                src: _vm.data.imgList[0].url,
                                fit: "cover",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("div", [
                                    _c("i", { staticClass: "el-icon-plus" }),
                                    _c("div", [_vm._v("上传图片")]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "cube-three" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cube-3",
                            style: {
                              margin:
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px",
                            },
                          },
                          [
                            _vm.data.imgList[1]
                              ? _c(
                                  "el-image",
                                  {
                                    style: {
                                      width: "100%",
                                      height: "100%",
                                      borderRadius:
                                        _vm.data.style.borRadius + "px",
                                    },
                                    attrs: {
                                      src: _vm.data.imgList[1].url,
                                      fit: "cover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("div", [
                                          _c("i", {
                                            staticClass: "el-icon-plus",
                                          }),
                                          _c("div", [_vm._v("上传图片")]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "cube-3",
                            style: {
                              margin:
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px",
                            },
                          },
                          [
                            _vm.data.imgList[2]
                              ? _c(
                                  "el-image",
                                  {
                                    style: {
                                      width: "100%",
                                      height: "100%",
                                      borderRadius:
                                        _vm.data.style.borRadius + "px",
                                    },
                                    attrs: {
                                      src: _vm.data.imgList[2].url,
                                      fit: "cover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("div", [
                                          _c("i", {
                                            staticClass: "el-icon-plus",
                                          }),
                                          _c("div", [_vm._v("上传图片")]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.data.style.type === "4"
                ? _c("div", { staticStyle: { overflow: "hidden" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "cube tz",
                        style: {
                          margin:
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px " +
                            _vm.data.style.marO +
                            "px",
                        },
                      },
                      [
                        _c(
                          "el-image",
                          {
                            style: {
                              width: "100%",
                              height: "100%",
                              borderRadius: _vm.data.style.borRadius + "px",
                            },
                            attrs: {
                              src: _vm.data.imgList[0].url,
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("div", [_vm._v("上传图片")]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "cube-fro" }, [
                      _c(
                        "div",
                        {
                          staticClass: "cube",
                          style: {
                            margin:
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px",
                          },
                        },
                        [
                          _vm.data.imgList[1]
                            ? _c(
                                "el-image",
                                {
                                  style: {
                                    width: "100%",
                                    height: "100%",
                                    borderRadius:
                                      _vm.data.style.borRadius + "px",
                                  },
                                  attrs: {
                                    src: _vm.data.imgList[1].url,
                                    fit: "cover",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("div", [
                                        _c("i", {
                                          staticClass: "el-icon-plus",
                                        }),
                                        _c("div", [_vm._v("上传图片")]),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "cube",
                          style: {
                            margin:
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px",
                          },
                        },
                        [
                          _vm.data.imgList[2]
                            ? _c(
                                "el-image",
                                {
                                  style: {
                                    width: "100%",
                                    height: "100%",
                                    borderRadius:
                                      _vm.data.style.borRadius + "px",
                                  },
                                  attrs: {
                                    src: _vm.data.imgList[2].url,
                                    fit: "cover",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("div", [
                                        _c("i", {
                                          staticClass: "el-icon-plus",
                                        }),
                                        _c("div", [_vm._v("上传图片")]),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.data.style.type === "5"
                ? _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { overflow: "hidden" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cube dz",
                          style: {
                            margin:
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px " +
                              _vm.data.style.marO +
                              "px",
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              style: {
                                width: "100%",
                                height: "100%",
                                borderRadius: _vm.data.style.borRadius + "px",
                              },
                              attrs: {
                                src: _vm.data.imgList[0].url,
                                fit: "cover",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("div", [
                                    _c("i", { staticClass: "el-icon-plus" }),
                                    _c("div", [_vm._v("上传图片")]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "cube-three" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cube-3",
                            style: {
                              margin:
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px " +
                                _vm.data.style.marO +
                                "px",
                            },
                          },
                          [
                            _vm.data.imgList[1]
                              ? _c(
                                  "el-image",
                                  {
                                    style: {
                                      width: "100%",
                                      height: "100%",
                                      borderRadius:
                                        _vm.data.style.borRadius + "px",
                                    },
                                    attrs: {
                                      src: _vm.data.imgList[1].url,
                                      fit: "cover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("div", [
                                          _c("i", {
                                            staticClass: "el-icon-plus",
                                          }),
                                          _c("div", [_vm._v("上传图片")]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "cube-2",
                              style: {
                                margin:
                                  _vm.data.style.marO +
                                  "px " +
                                  _vm.data.style.marO +
                                  "px " +
                                  _vm.data.style.marO +
                                  "px " +
                                  _vm.data.style.marO +
                                  "px",
                              },
                            },
                            [
                              _vm.data.imgList[2]
                                ? _c(
                                    "el-image",
                                    {
                                      style: {
                                        width: "100%",
                                        height: "100%",
                                        borderRadius:
                                          _vm.data.style.borRadius + "px",
                                      },
                                      attrs: {
                                        src: _vm.data.imgList[2].url,
                                        fit: "cover",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("div", [
                                            _c("i", {
                                              staticClass: "el-icon-plus",
                                            }),
                                            _c("div", [_vm._v("上传图片")]),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "cube-2",
                              style: {
                                margin:
                                  _vm.data.style.marO +
                                  "px " +
                                  _vm.data.style.marO +
                                  "px " +
                                  _vm.data.style.marO +
                                  "px " +
                                  _vm.data.style.marO +
                                  "px",
                              },
                            },
                            [
                              _vm.data.imgList[3]
                                ? _c(
                                    "el-image",
                                    {
                                      style: {
                                        width: "100%",
                                        height: "100%",
                                        borderRadius:
                                          _vm.data.style.borRadius + "px",
                                      },
                                      attrs: {
                                        src: _vm.data.imgList[3].url,
                                        fit: "cover",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("div", [
                                            _c("i", {
                                              staticClass: "el-icon-plus",
                                            }),
                                            _c("div", [_vm._v("上传图片")]),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }