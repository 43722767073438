<template>
    <div class="x-f-end padR13">
        <div style="background-color: #FFFFFF;border-radius: 50px;padding: 10px;boxShadow: #ccc 0px 0px 10px">
        <iconpark-icon name="headset-one" size="26px"></iconpark-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: 'kefu',
    props:{
        data:{
            type: Object,
            default: {}
        }
    }
}
</script>

<style scoped>

</style>
