var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "x-f-end padR13" }, [
    _c(
      "div",
      {
        staticStyle: {
          "background-color": "#FFFFFF",
          "border-radius": "50px",
          padding: "10px",
          boxShadow: "#ccc 0px 0px 10px",
        },
      },
      [_c("iconpark-icon", { attrs: { name: "headset-one", size: "26px" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }