var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: {
          margin:
            _vm.data.marT +
            "px " +
            _vm.data.marR +
            "px " +
            _vm.data.marB +
            "px " +
            _vm.data.marL +
            "px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "warp",
            style: {
              borderRadius: _vm.data.borRadius + "px",
              backgroundColor: _vm.data.contentStyle.bgColor,
            },
          },
          [
            _c("div", { staticClass: "x-bc marB15" }, [
              _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "fontWeight7 fontS15",
                    style: {
                      color: _vm.data.contentStyle.titleColor,
                    },
                  },
                  [_vm._v("评价 ")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.contentStyle.isNumberEvaluations,
                        expression: "data.contentStyle.isNumberEvaluations",
                      },
                    ],
                    style: {
                      color: _vm.data.contentStyle.titleColor,
                    },
                  },
                  [_vm._v("(10万+)")]
                ),
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.contentStyle.isReadMore,
                        expression: "data.contentStyle.isReadMore",
                      },
                    ],
                    staticClass: "annotateGrey",
                  },
                  [_vm._v("更多")]
                ),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.contentStyle.isReadMore,
                      expression: "data.contentStyle.isReadMore",
                    },
                  ],
                  staticClass: "annotateGrey el-icon-arrow-right",
                }),
              ]),
            ]),
            _c("div", { staticClass: "x-b marB10" }, [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c("el-image", {
                    staticClass: "marR5",
                    staticStyle: {
                      width: "40px",
                      height: "40px",
                      "border-radius": "50%",
                    },
                    attrs: {
                      src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg",
                    },
                  }),
                  _c("div", [
                    _c("div", { staticClass: "author" }, [
                      _c("span", [_vm._v("烟雨江南")]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.data.contentStyle.isVipLevel,
                              expression: "data.contentStyle.isVipLevel",
                            },
                          ],
                          staticClass: "vipSign",
                        },
                        [_vm._v("会员")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.data.contentStyle.isEvaluationLeve,
                            expression: "data.contentStyle.isEvaluationLeve",
                          },
                        ],
                      },
                      [
                        _c("span", { staticClass: "iconfont icon-shoucang" }),
                        _c("span", { staticClass: "iconfont icon-shoucang" }),
                        _c("span", { staticClass: "iconfont icon-shoucang" }),
                        _c("span", { staticClass: "iconfont icon-shoucang" }),
                        _c("span", { staticClass: "iconfont icon-shoucang1" }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.contentStyle.isTimeEvaluations,
                      expression: "data.contentStyle.isTimeEvaluations",
                    },
                  ],
                  staticClass: "annotateGrey",
                },
                [_vm._v("2018-06-18")]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "omitTwo fontS12c3 marB10",
                staticStyle: { width: "100%" },
              },
              [
                _vm._v(
                  " 我最近在一家蛋糕店尝试了他们的蛋糕，真的非常好吃！蛋糕的质地非常细腻，入口即化，而且甜度非常适中，不会太甜也不会太淡，非常好吃。 "
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.data.contentStyle.isImgEvaluations,
                    expression: "data.contentStyle.isImgEvaluations",
                  },
                ],
                staticClass: "marB5",
              },
              [
                _c("el-image", {
                  staticClass: "marR5",
                  staticStyle: { "border-radius": "10px" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/0da460dff13a40e98fca93f17e3b15d9.png",
                  },
                }),
                _c("el-image", {
                  staticClass: "marR5",
                  staticStyle: { "border-radius": "10px" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/a768df0265044d0b9469501bf900271d.png",
                  },
                }),
                _c("el-image", {
                  staticStyle: { "border-radius": "10px" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/83dde722d40e4c68aeadf19fda72059c.png",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.data.contentStyle.isGoodsSpece,
                    expression: "data.contentStyle.isGoodsSpece",
                  },
                ],
                staticClass: "fontS12c3 annotateGrey",
              },
              [_vm._v(" 约8英寸（850g） ")]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }