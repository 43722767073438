<template>
  <!-- 内容 -->
  <div
    :style="{
      margin:
        data.marT +
        'px ' +
        data.marR +
        'px ' +
        data.marB +
        'px ' +
        data.marL +
        'px'
    }"
  >
    <!-- 内容 -->
    <div
      class="warp x-w"
      :style="{
        borderRadius: data.borRadius + 'px',
        backgroundColor: data.style.bgColor
      }"
    >
      <div
        class="iconItem x-fc"
        v-for="(item, index) in data.contentList"
        :key="index"
        :style="{
          width:
            data.lineNum === 3
              ? '33.33333%'
              : data.lineNum === 4
              ? '25%'
              : '20%',
          boxSizing: 'border-box'
        }"
      >
        <div class="y-c">
          <iconParkALi
            :color="item.icon ? data.style.iconColor : ''"
            v-show="data.btnType === 'icon'"
            :name="item.icon ? item.icon : 'icon-morentubiao'"
            size="28px"
          />
          <el-image
            v-show="data.btnType === 'img'"
            style="width: 28px; height: 28px"
            :src="
              item.imgUrl
                ? item.imgUrl
                : 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-13/bbae70119c51444cb5b67a529186025b.png'
            "
          />
          <p
            :style="{
              color: data.style.textColor
            }"
          >
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tubiaozu',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
//内容
.warp {
  // padding: 15px;
  .iconItem {
    height: 70px;
  }
}
</style>
