<template>
    <!-- 商品组件专用 商品每一项 -->
    <div
        class="goodsItem"
        :class="{
      'x-x': overallFlex,
      bgWhite: curGoodsStyleIndex !== 3,
      simple: curGoodsStyleIndex === 3,
      'card-shadow': curGoodsStyleIndex === 1,
      card2: curGoodsStyleIndex === 2,
      filletedCorner: moreSet.goodsBorderAngle === 'filletedCorner',
      'x-w': lineFeed
    }"
    >
        <div class="goods-photo">
            <img :src="goods.goodsUrl || src" alt="加载失败" :style="{height: `${heightImg}px`}" ref="goodsPhoto"/>

            <div class="goods-box">
                <div v-show="moreSet.isShowCountDown" class="x-bc" :class="[streamer > 12 ? 'streamer' : 'streamer2']">
                    <span class="title">限时活动</span>
                    <div class="y-start ">
                        <span v-if="streamer > 12" style="opacity: .6;">距结束仅剩</span>
                        <span>00 时 00 分 00</span>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="goods-info"
            ref="goodsInfo"
            :class="{ card: curGoodsStyleIndex === 3 , center: moreSet.textAlignment === 'center'}"
        >
            <div
                class="goods-info-title omit1" v-show="moreSet.isShowName"
                :class="{ blodFont: moreSet.textStyle === '加粗', center: moreSet.textAlignment === 'center' }"
            >
                <span>{{ goods.goodsName }}</span>
            </div>
            <div class="sub-title omit1" v-show="moreSet.isShowDescribe" :class="moreSet.textAlignment === 'center' ? 'center' : ''">
                <span>{{ goods.sellingPoints }}</span>
            </div>
            <div
                class="goods-info-price"
                :class="{ center: moreSet.textAlignment === 'center' }"
            >
                <div class="price x-fc">
                    <div
                        class="sale-price"
                        v-show="moreSet.isShowFightGroupPrice"
                        :class="{ blodFont: moreSet.textStyle === '加粗' }"
                    >
                        <!--                       <div v-show="moreSet.isShowFightGroupPrice">-->
                        <span class="price-tag">¥</span>
                        <span class="price-sale-price">{{ goods.activityPrice }}</span>
                        <!--                       </div>-->
                    </div>
                    <div class="original-price" v-show="moreSet.isShowOriginalPrice">
                        <span class="price-tag">¥</span>
                        <span class="price-sale-price">{{ goods.liningPrice }}</span>
                    </div>
                </div>
                <div
                    class="shoppingCart"
                    :class="{ center: moreSet.textAlignment === 'center' }"
                    v-show="CardIndex !== 8"
                >
                    <!-- 购物车图标 -->
                    <iconpark-icon
                        v-show="shoppingCartStyle"
                        :name="shoppingCart[CardIndex].icon"
                        color="#fe5a34"
                        size="24px"
                    ></iconpark-icon>
                    <!-- 自定义购物车文字 -->
                    <div
                        class="cartIcon"
                        :class="{
              circleSolid: CardIndex === 4,
              circleHollow: CardIndex === 6,
              rightAngleSolid: CardIndex === 5,
              rightAngleHollow: CardIndex === 7
            }"
                        v-show="!shoppingCartStyle"
                    >
                        {{ CardText }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 角标 -->
        <div v-show="moreSet.goodsCornerMark === 1">
            <!-- 角标 新品-->
            <div class="cornerMark-one" v-show="moreSet.curCornerMarkIndex === 0">
                新品
            </div>
            <!-- 角标 热卖 -->
            <div class="cornerMark-two" v-show="moreSet.curCornerMarkIndex === 1">
                热卖
            </div>
            <!-- 角标 new -->
            <div class="cornerMark-three" v-show="moreSet.curCornerMarkIndex === 2">
                NEW
            </div>
            <!-- 角标 hot -->
            <div class="cornerMark-four" v-show="moreSet.curCornerMarkIndex === 3">
                <div class="baseSquare">
                    <div class="square">
                        <div class="text">HOT</div>
                    </div>
                </div>
            </div>
            <!-- 自定义角标 -->
            <div class="cornerMark-five" v-show="moreSet.curCornerMarkIndex === 4">
                <img :src="moreSet.cornerMarkUrl" alt="加载失败" v-if="moreSet.cornerMarkUrl">
                <img src="@/assets/images/mty.png" alt="加载失败" v-else>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'timeLimitActivity',
    props: {
        //整体弹性布局
        overallFlex: {
            type: Boolean,
            default: false
        },
        lineFeed:{
            type: Boolean,
            default: false
        },
        //商品图片宽高
        photoWidthHeight: {
            type: Object,
            default: () => ({
                width: '155px',
                height: '155px'
            })
        },
        //商品信息高度
        infoHeight: {
            type: String
        },
        //当前商品样式
        curGoodsStyleIndex: {
            type: Number,
            default: 0
        },
        //控制更多设置区域数据
        moreSet: {
            type: Object
        },
        //购物车样式下标
        CardIndex: {
            type: Number
        },
        //自定义购物车文字
        CardText: {
            type: String
        },
        // 商品信息
        goodsItem: {
            type: Object,
            default: () => {
            }
        },
        // 是否显示拼团倒计时
        showActivityTime:{
            type: Boolean,
            default: false
        },
        // 倒计时框样式大小
        streamer:{
            type: String
        },
        // 图片比例
        goodsRatio:{
            type: String,
            default: '1:1'
        }
    },
    data() {
        return {
            src: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png',
            goods: {}, // 商品信息
            //购物车样式数组
            shoppingCart: [
                { id: 1, icon: 'gouwuche1', text: '购物车1' },
                { id: 2, icon: 'gouwuche2', text: '购物车2' },
                { id: 3, icon: 'add-one-8p45gln2', text: '空心加购' },
                { id: 4, icon: 'add-one', text: '实心加购' },
                { id: 5, icon: 'anniu2', text: '圆角实心' },
                { id: 6, icon: 'anniu3', text: '直角实心' },
                { id: 7, icon: 'anniu1', text: '圆角空心' },
                { id: 8, icon: 'anniu4', text: '直角空心' },
                { id: 9, icon: 'buxianshi', color: '#b5b5b5', text: '不显示' }
            ],
            heightImg: 165,
        }
    },
    created() {
    },
    watch: {
        photoWidthHeight: {
            handler (val) {
                if (val) {
                    // console.log('val', val)
                    this.$nextTick(() => {
                        this.$refs['goodsPhoto'].style.width = val.width
                        this.$refs['goodsPhoto'].style.height = val.height
                    })
                }
            },
            immediate: true,
            deep: true
        },
        infoHeight: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        if(this.$refs.goodsInfo?.style?.height){
                            console.log('=-=-=-=-=-=:', this.$refs.goodsInfo.style)
                            this.$refs.goodsInfo.style.height = val
                        }
                    })
                }
            },
            immediate: true
        },
        goodsItem: {
            handler(nVal) {
                if (nVal) {
                    this.goods = nVal
                    // console.log('商品信息数据ITEM****：', this.goods)
                } else {
                    this.goods = {
                        goodsName: '商品名称',
                        sellingPoints: '商品描述',
                        fightGroupPrice: 99,
                        liningPrice: 999,
                        goodsUrl: require('@/assets/images/mty.png')

                    }
                }
            },
            immediate: true,
            deep: true
        },
        // 按比例缩放图片
        goodsRatio: {
            handler(nVal) {
                this.$nextTick(() => {
                    let width = this.$refs.goodsPhoto.width
                    let height
                    console.log('缩放2------', width)
                    if (nVal === '1:1') {
                        height = (width / 1) * 1
                    } else if (nVal === '3:2') {
                        height = (width / 3) * 2
                    } else if (nVal === '3:4') {
                        height = (width / 3) * 4
                    } else if (nVal === '16:9') {
                        height = (width / 16) * 9
                    }
                    this.$refs.goodsPhoto.style.height = height + 'px'
                })

            }

        }
    },
    computed: {
        //显示购物车样式 (下标4.5.6.7是自定义购物车图标文字,下标0,1,2,3为购物车图标)
        shoppingCartStyle() {
            let bool
            bool =
                this.CardIndex === 4 ||
                this.CardIndex === 5 ||
                this.CardIndex === 6 ||
                this.CardIndex === 7
                    ? false
                    : true
            return bool
        }
    },
    methods:{
        format(percentage) {
            return percentage === 100 ? '' : ``;
        }
    }
}
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

//商品每一项
.goodsItem {
    width: 100%;
    position: relative;
    //圆角
    &.filletedCorner {
        border-radius: 10px;
    }

    //无边白底
    &.bgWhite {
        background-color: #fff;
    }

    //卡片投影
    &.card-shadow {
        box-shadow: 0 2px 4px rgb(0 0 0 / 6%);
    }

    //描边白底
    &.card2 {
        border: 1px solid rgba(50, 50, 51, 0.1);
    }

    //无边透明底
    &.simple {
        background-color: inherit;
    }
    .goods-photo {
        width: 100%;
        position: relative;
        img {
            width: 100%;
            height: 155px;
        }
        .goods-box{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        .alreadyGroupSize{
            background-color: #f35a29;
            color: #FFFFFF;
            font-size: 0.5em;
            padding: 5px;
            border-radius: 5px;
            width: 60px;
            margin-left: 5px;
        }
        .streamer{
            width: 100%;
            padding: 5px 10px;
            background: linear-gradient(to right, #f76e2f, #e81817);;
            color: #FFFFFF;
            .title{
                font-size: 1.2em;
            }
            span{
                font-size: 1em;
            }
        }
        .streamer2{
            width: 100%;
            padding: 3px 5px;
            background: linear-gradient(to right, #f76e2f, #e81817);;
            color: #FFFFFF;
            .title{
                font-size: 13px;
            }
            span{
                font-size: 12px;
            }
        }

    }

    .progressBox{
        width: 100%;
        height: 7px;
        background-color: #e5eaf4;
        border-radius: 50px;
        .progress{
            height: 7px;
            border-radius: 50px;
            background-color: #ff3e54;
        }
    }

    .goods-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 10px;
        width: 100%;
        //无边白底
        &.card {
            margin: 0;
        }

        .goods-info-title {
            margin-top: 10px;
            //文本对齐(居中对齐)
            &.center {
                text-align: center;
            }

            .title {
                //超出两行显示省略号
                max-height: 40px;
                font-size: 14px;
                line-height: 18px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin: 0 0 5px 0;
                white-space: pre-wrap;
                //文本样式(加粗)
                &.blodFont {
                    font-weight: bold;
                }
            }

            .sub-title {
                //超出1行显示省略号
                height: 16px;
                color: #969799;
                font-size: 14px;
                line-height: 16px;
                word-break: break-all;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: pre-wrap;
            }

        }
        .blodFont {
            font-weight: bold;
        }
        .center {
            text-align: center;
        }

        .goods-info-price {
            display: flex;
            justify-content: space-between;
            line-height: 45px;
            margin-right: 7px;
            //文本对齐(居中对齐)
          

            .sale-price {
                color: #fe5a34;
                font-size: 18px;

                .price-tag {
                    font-size: 14px;
                }

                //文本样式(加粗)
              
            }

            .original-price {
                text-decoration: line-through;
                margin-left: 5px;
                color: #969799;
            }

            .shoppingCart {
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: end;
                flex: 1;
                //文本对齐(居中对齐)
                &.center {
                    display: block;
                    text-align: center;
                }

                .cartIcon {
                    display: inline-block;
                    height: 24px;
                    padding: 0 8px;
                    line-height: 24px;
                    //圆角实心
                    &.circleSolid {
                        background-color: #fe5a34;
                        color: #fff;
                        border-radius: 12px;
                    }

                    //圆角空心
                    &.circleHollow {
                        border: 1px solid #fe5a34;
                        color: #fe5a34;
                        border-radius: 12px;
                    }

                    //直角实心
                    &.rightAngleSolid {
                        background-color: #fe5a34;
                        color: #fff;
                    }

                    //直角空心
                    &.rightAngleHollow {
                        border: 1px solid #fe5a34;
                        color: #fe5a34;
                    }
                }
            }
        }
    }

    //角标
    //新品角标
    .cornerMark-one {
        position: absolute;
        top: 10px;
        left: 0;
        width: 45px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        background-color: #fe5a34;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        color: #fff;
        font-weight: bold;
    }

    //热卖角标
    .cornerMark-two {
        position: absolute;
        top: 10px;
        left: 15px;
        width: 25px;
        height: 42px;
        line-height: 16px;
        padding: 5px 0;
        background-color: #fe5a34;
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
    }

    //NEW角标
    .cornerMark-three {
        position: absolute;
        top: -1px;
        left: 10px;
        width: 35px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        background-color: #fe5a34;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    //HOT角标
    .cornerMark-four {
        position: absolute;
        top: 10px;
        left: 10px;

        .baseSquare {
            width: 30px;
            height: 30px;
            background-color: #fe5a34;
            transform: rotate(45deg);
            border-radius: 2px;

            .square {
                width: 30px;
                height: 30px;
                background-color: #fe5a34;
                transform: rotate(-45deg);
                border-radius: 2px;

                .text {
                    color: #fff;
                    line-height: 30px;
                    text-align: center;
                    font-weight: bold;
                    transform: rotate(-5deg);
                }
            }
        }
    }

    //自定义角标
    .cornerMark-five {
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
        height: 35px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
