var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: {
          margin:
            _vm.data.marT +
            "px " +
            _vm.data.marR +
            "px " +
            _vm.data.marB +
            "px " +
            _vm.data.marL +
            "px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "warp",
            style: {
              borderRadius: _vm.data.borRadius + "px",
              backgroundColor: _vm.data.contentStyle.bgColor,
            },
          },
          [
            _c("div", { staticClass: "x-bc" }, [
              _c(
                "span",
                {
                  staticClass: "marR15",
                  style: {
                    color: _vm.data.contentStyle.titleColor,
                  },
                },
                [_vm._v("选择")]
              ),
              _c("div", { staticClass: "x-f" }, [
                _c(
                  "span",
                  {
                    style: {
                      color: _vm.data.contentStyle.textColor,
                    },
                  },
                  [_vm._v("请选择商品规格")]
                ),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }