<template>
    <div class="warp x-bc" :style="{
        background: data.isLayout === 1 ? data.style.bgColor : '',
        backgroundImage: data.isLayout === 2 ? `url(${data.bgImg})` : '',
        backgroundRepeat: 'no-repeat',
        margin:
            data.marT +
            'px ' +
            data.marR +
            'px ' +
            data.marB +
            'px ' +
            data.marL +
            'px',
        borderRadius: data.borRadius + 'px'
    }">
        <!-- 左边 -->
        <div class="x-f" :style="{

        }">
            <div v-if="data.logType !== 2" :style="{
                borderRadius: data.logType === 0 ? '30px' : '6px',
                overflow: 'hidden'
            }">
                <!-- <el-image :style="{ width: '48px', height: '48px', borderRadius: data.logType === 0 ? '30px' : '6px', }"
                    :src="data.loImg" fit="cover"> -->
                <el-image :style="{ width: '48px', height: '48px', borderRadius: data.logType === 0 ? '30px' : '6px', }"
                    src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/531076396c104f8782c39f893f1055d5.png" fit="cover">
                    
                    <div slot="error" class="image-slot">
                        <div>
                            <i class="el-icon-plus"></i>
                            <div>上传图片</div>
                        </div>
                    </div>
                </el-image>
            </div>
            <div style="margin-left: 10px;">
                <span class="fontS14 fontWeight7" :style="{
                    color: data.style.iconColor
                }">店铺名称</span>
                <br />
                <span class="fontS12 annotateGrey" :style="{
                    color: data.style.angleMarkColor
                }">>100m
                </span>
                <span :style="{
                    color: data.style.leftBtnColor
                }">
                    |
                </span>
                <span :style="{
                    color: data.style.textColor
                }">
                    <iconpark-icon class="" name="shouhuodizhi1" size="8px" />
                    门店位置定位位置
                </span>
            </div>
        </div>
        <!-- 右边 -->
        <div class="switch">
            <!-- <div class="receivingMethod1Btn">{{ data.receivingMethod1 }}</div> -->
            
            <div class="receivingMethod1Btn" :style="{border: `1px solid ${data.iconBor}`}">
                <iconParkALi :name="data.seeMoreIcon" size="12px" :style="{color: data.iconColor}" />
                <span :style="{color: data.iconText}" >
                    切换门店
                </span>
            </div>
            <!-- <div>{{ data.receivingMethod2 }}</div> -->
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'switching',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {}
    },
    methods: {}
}
</script>
  
<style lang="scss" scoped>
//大盒
.warp {
    height: 57px;
    background-color: #fff;
    padding: 0 10px;

    //定位
    .el-icon-location-outline {
        font-size: 23px;
    }

    //开关
    .switch {
        display: flex;
        font-size: 12px;
        color: #333333;
        border-radius: 42px;
        background-color: #eeeeee;
        margin-right: 15px;

        div {
            padding: 5px 13px 6px;
            margin: -1px;
        }

        //收货方式第一种
        .receivingMethod1Btn {
            color: #fff;
            background-color: #fe5f3a;
            margin-right: -5px;
            border-radius: 42px;
        }
    }
}

::v-deep .image-slot {
    text-align: center;
    margin-top: 22px;
}
</style>
  