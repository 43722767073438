var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gonggao x-f",
      staticStyle: { height: "40px", padding: "0 10px" },
      style: { background: _vm.data.style.bgColor },
    },
    [
      _c("iconpark-icon", {
        attrs: { name: "speaker-one", color: "#ed6a0c", size: "18px" },
      }),
      _c(
        "span",
        {
          staticStyle: { "margin-left": "10px" },
          style: { color: _vm.data.style.textColor },
        },
        [_vm._v(_vm._s(_vm.data.title))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }