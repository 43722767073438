var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: {
          margin:
            _vm.data.marT +
            "px " +
            _vm.data.marR +
            "px " +
            _vm.data.marB +
            "px " +
            _vm.data.marL +
            "px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "warp x-bc",
            style: {
              borderTopLeftRadius: _vm.data.borTopRadius + "px",
              borderTopRightRadius: _vm.data.borTopRadius + "px",
              borderBottomLeftRadius: _vm.data.borBottomRadius + "px",
              borderBottomRightRadius: _vm.data.borBottomRadius + "px",
              backgroundColor:
                _vm.data.style.bgColorType === "bgc"
                  ? _vm.data.style.bgColor
                  : "",
            },
          },
          [
            _c(
              "div",
              {
                class:
                  _vm.data.isAssistantEnable &&
                  _vm.data.assistantSetType === "leftRight"
                    ? "xF"
                    : "yStart",
              },
              [
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _c("el-image", {
                      staticClass: "marR5",
                      staticStyle: { width: "28px", height: "28px" },
                      attrs: { src: _vm.data.titleUrl },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "fontWeight7",
                        style: {
                          color: _vm.data.titleStyle.textColor,
                          fontSize: _vm.data.titleStyle.fontSize + "px",
                          borderBottom:
                            _vm.data.titleStyle.titleType === "optionsBar"
                              ? "4px" +
                                " solid" +
                                _vm.data.titleStyle.underlineColor
                              : "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.data.title))]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "marR5",
                      style: {
                        color: _vm.data.assistantStyle.textColor,
                        fontSize: _vm.data.assistantStyle.fontSize + "px",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.data.assistantTitle) + "到期 ")]
                  ),
                  _c(
                    "span",
                    {
                      style: {
                        fontSize: _vm.data.seeMoreStyle.fontSize + "px",
                        color: _vm.data.seeMoreStyle.textColor,
                      },
                    },
                    [_vm._v(_vm._s(_vm.data.seeMoreTitle))]
                  ),
                  _c("iconParkALi", {
                    attrs: {
                      name: _vm.data.seeMoreIcon,
                      size: "18px",
                      color: _vm.data.seeMoreStyle.iconColor,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }