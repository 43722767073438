<template>
  <div>
    <!-- 内容 -->
    <div
      :style="{
        margin:
          data.marT +
          'px ' +
          data.marR +
          'px ' +
          data.marB +
          'px ' +
          data.marL +
          'px'
      }"
    >
      <!-- 内容 -->
      <div
        class="warp"
        :style="{
          borderRadius: data.borRadius + 'px',
          backgroundColor: data.contentStyle.bgColor
        }"
      >
        <div class="x-bc">
          <span
            :style="{
              color: data.contentStyle.titleColor
            }"
            class="marR15"
            >选择</span
          >
          <div class="x-f">
            <span
              :style="{
                color: data.contentStyle.textColor
              }"
              >请选择商品规格</span
            >
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shangpinguige',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//内容
.warp {
  padding: 15px 10px;
}
</style>
