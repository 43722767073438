var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "im-box",
        style: {
          padding: _vm.data.type > 1 ? "40px 10px 0px 10px" : "",
          background:
            _vm.data.type === 2
              ? "linear-gradient(to bottom, " +
                _vm.data.contentList[_vm.active].title +
                " 0%, #f2f2f2 40%)"
              : "#fff",
        },
      },
      [
        _vm.data.type === 3
          ? _c("div", {
              style: {
                backgroundColor: _vm.data.contentList[_vm.active].title,
                borderBottomLeftRadius: _vm.data.type === 3 ? "15px" : "",
                borderBottomRightRadius: _vm.data.type === 3 ? "15px" : "",
                position: "absolute",
                top: 0,
                left: 0,
                height: "200px",
                width: "100%",
              },
            })
          : _vm._e(),
        _c(
          "div",
          {
            style: {
              margin:
                _vm.data.marT +
                "px " +
                _vm.data.marR +
                "px " +
                _vm.data.marB +
                "px " +
                _vm.data.marL +
                "px",
              overflow: "hidden",
              borderRadius: _vm.data.borRadius + "px",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "warp x-fc",
                style: {
                  borderRadius: _vm.data.borRadius + "px",
                  // backgroundColor: data.style.bgColor
                },
              },
              [
                _c("div", { staticClass: "carousel-container" }, [
                  _c(
                    "div",
                    { staticClass: "carousel-inner" },
                    [
                      this.data.isIcon
                        ? _c(
                            "el-carousel",
                            {
                              attrs: { height: _vm.carouselHeight },
                              on: { change: _vm.changeImg },
                            },
                            _vm._l(
                              _vm.data.contentList,
                              function (item, index) {
                                return _c(
                                  "el-carousel-item",
                                  { key: index },
                                  [
                                    _c("el-image", {
                                      style: {
                                        borderRadius: _vm.data.borRadius + "px",
                                      },
                                      attrs: { src: item.icon, fit: "cover" },
                                      on: {
                                        load: function (event) {
                                          return _vm.updateCarouselHeight(
                                            event,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      !this.data.isIcon
                        ? _c(
                            "el-carousel",
                            {
                              attrs: { height: _vm.carouselHeight },
                              on: { change: _vm.changeImg },
                            },
                            _vm._l(
                              _vm.data.contentList,
                              function (item, index) {
                                return _c(
                                  "el-carousel-item",
                                  { key: index, attrs: { label: index + 1 } },
                                  [
                                    _c("el-image", {
                                      style: {
                                        borderRadius: _vm.data.borRadius + "px",
                                      },
                                      attrs: { src: item.icon, fit: "cover" },
                                      on: {
                                        load: function (event) {
                                          return _vm.updateCarouselHeight(
                                            event,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }