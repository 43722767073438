var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "goodsItem",
      class: {
        "x-x": _vm.overallFlex,
        bgWhite: _vm.curGoodsStyleIndex !== 3,
        simple: _vm.curGoodsStyleIndex === 3,
        "card-shadow": _vm.curGoodsStyleIndex === 1,
        card2: _vm.curGoodsStyleIndex === 2,
        filletedCorner: _vm.moreSet.goodsBorderAngle === "filletedCorner",
        "x-w": _vm.lineFeed,
      },
    },
    [
      _c("div", { staticClass: "goods-photo" }, [
        _c("img", {
          ref: "goodsPhoto",
          style: { height: _vm.heightImg + "px" },
          attrs: { src: _vm.goods.goodsUrl || _vm.src, alt: "加载失败" },
        }),
        _c("div", { staticClass: "goods-box" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSet.isShowCountDown,
                  expression: "moreSet.isShowCountDown",
                },
              ],
              staticClass: "x-bc",
              class: [_vm.streamer > 12 ? "streamer" : "streamer2"],
            },
            [
              _c("span", { staticClass: "title" }, [_vm._v("限时活动")]),
              _c("div", { staticClass: "y-start " }, [
                _vm.streamer > 12
                  ? _c("span", { staticStyle: { opacity: ".6" } }, [
                      _vm._v("距结束仅剩"),
                    ])
                  : _vm._e(),
                _c("span", [_vm._v("00 时 00 分 00")]),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          ref: "goodsInfo",
          staticClass: "goods-info",
          class: {
            card: _vm.curGoodsStyleIndex === 3,
            center: _vm.moreSet.textAlignment === "center",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSet.isShowName,
                  expression: "moreSet.isShowName",
                },
              ],
              staticClass: "goods-info-title omit1",
              class: {
                blodFont: _vm.moreSet.textStyle === "加粗",
                center: _vm.moreSet.textAlignment === "center",
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.goods.goodsName))])]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSet.isShowDescribe,
                  expression: "moreSet.isShowDescribe",
                },
              ],
              staticClass: "sub-title omit1",
              class: _vm.moreSet.textAlignment === "center" ? "center" : "",
            },
            [_c("span", [_vm._v(_vm._s(_vm.goods.sellingPoints))])]
          ),
          _c(
            "div",
            {
              staticClass: "goods-info-price",
              class: { center: _vm.moreSet.textAlignment === "center" },
            },
            [
              _c("div", { staticClass: "price x-fc" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.moreSet.isShowFightGroupPrice,
                        expression: "moreSet.isShowFightGroupPrice",
                      },
                    ],
                    staticClass: "sale-price",
                    class: { blodFont: _vm.moreSet.textStyle === "加粗" },
                  },
                  [
                    _c("span", { staticClass: "price-tag" }, [_vm._v("¥")]),
                    _c("span", { staticClass: "price-sale-price" }, [
                      _vm._v(_vm._s(_vm.goods.activityPrice)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.moreSet.isShowOriginalPrice,
                        expression: "moreSet.isShowOriginalPrice",
                      },
                    ],
                    staticClass: "original-price",
                  },
                  [
                    _c("span", { staticClass: "price-tag" }, [_vm._v("¥")]),
                    _c("span", { staticClass: "price-sale-price" }, [
                      _vm._v(_vm._s(_vm.goods.liningPrice)),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.CardIndex !== 8,
                      expression: "CardIndex !== 8",
                    },
                  ],
                  staticClass: "shoppingCart",
                  class: { center: _vm.moreSet.textAlignment === "center" },
                },
                [
                  _c("iconpark-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.shoppingCartStyle,
                        expression: "shoppingCartStyle",
                      },
                    ],
                    attrs: {
                      name: _vm.shoppingCart[_vm.CardIndex].icon,
                      color: "#fe5a34",
                      size: "24px",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.shoppingCartStyle,
                          expression: "!shoppingCartStyle",
                        },
                      ],
                      staticClass: "cartIcon",
                      class: {
                        circleSolid: _vm.CardIndex === 4,
                        circleHollow: _vm.CardIndex === 6,
                        rightAngleSolid: _vm.CardIndex === 5,
                        rightAngleHollow: _vm.CardIndex === 7,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.CardText) + " ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.moreSet.goodsCornerMark === 1,
              expression: "moreSet.goodsCornerMark === 1",
            },
          ],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSet.curCornerMarkIndex === 0,
                  expression: "moreSet.curCornerMarkIndex === 0",
                },
              ],
              staticClass: "cornerMark-one",
            },
            [_vm._v(" 新品 ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSet.curCornerMarkIndex === 1,
                  expression: "moreSet.curCornerMarkIndex === 1",
                },
              ],
              staticClass: "cornerMark-two",
            },
            [_vm._v(" 热卖 ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSet.curCornerMarkIndex === 2,
                  expression: "moreSet.curCornerMarkIndex === 2",
                },
              ],
              staticClass: "cornerMark-three",
            },
            [_vm._v(" NEW ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSet.curCornerMarkIndex === 3,
                  expression: "moreSet.curCornerMarkIndex === 3",
                },
              ],
              staticClass: "cornerMark-four",
            },
            [_vm._m(0)]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSet.curCornerMarkIndex === 4,
                  expression: "moreSet.curCornerMarkIndex === 4",
                },
              ],
              staticClass: "cornerMark-five",
            },
            [
              _vm.moreSet.cornerMarkUrl
                ? _c("img", {
                    attrs: { src: _vm.moreSet.cornerMarkUrl, alt: "加载失败" },
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/images/mty.png"),
                      alt: "加载失败",
                    },
                  }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "baseSquare" }, [
      _c("div", { staticClass: "square" }, [
        _c("div", { staticClass: "text" }, [_vm._v("HOT")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }