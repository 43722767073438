var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "fengefuzhu",
        style: {
          height:
            _vm.data.style.auxiliaryType == "wuxian"
              ? _vm.data.style.borderHeight + "px"
              : "",
          paddingLeft: _vm.data.style.backGauge + "px",
          paddingRight: _vm.data.style.backGauge + "px",
        },
      },
      [
        _vm.data.style.auxiliaryType == "youxian"
          ? _c("hr", {
              style: {
                borderTop:
                  "1px " +
                  _vm.data.style.lineStyle +
                  " " +
                  _vm.data.style.color,
              },
            })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }