<template>
  <div class="warp x-bc">
    <!-- 左边 -->
    <div class="x-f" v-show="data.isLayout === 0">
      <iconpark-icon class="marL10 marR10" name="shouhuodizhi1" size="18px" />
      <div>
        <span class="fontS14 fontWeight7">店铺名称</span>
        <br />
        <span class="fontS12 annotateGrey">距离您80m</span>
      </div>
    </div>
    <!-- 左边 -->
    <div class="x-f" v-show="data.isLayout === 1">
      <iconpark-icon class="marL10 marR10" name="shouhuodizhi1" size="18px" />
      <div>
        <span class="fontS14 fontWeight7">品牌名称</span>
        <br />
        <div class="x-f">
          <span class="fontS12 annotateGrey marR5">{城市} {店铺名称}</span>
          <iconpark-icon class="annotateGrey" name="down" size="18px" />
        </div>
      </div>
    </div>
    <!-- 右边 -->
    <div class="switch">
      <div class="receivingMethod1Btn">{{ data.receivingMethod1 }}</div>
      <div>{{ data.receivingMethod2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fuwufangshi',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//大盒
.warp {
  height: 57px;
  background-color: #fff;
  //定位
  .el-icon-location-outline {
    font-size: 23px;
  }
  //开关
  .switch {
    display: flex;
    font-size: 12px;
    color: #333333;
    border-radius: 42px;
    background-color: #eeeeee;
    margin-right: 15px;
    div {
      padding: 5px 13px 6px;
      margin: -1px;
    }
    //收货方式第一种
    .receivingMethod1Btn {
      color: #fff;
      background-color: #fe5f3a;
      margin-right: -5px;
      border-radius: 42px;
    }
  }
}
</style>
