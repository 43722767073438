<template>
    <div style="float: right;">
        <div class="box">
            <div class="content" v-for="(item, index) in data.immageNavList" :key="index">
                <img :src="item.imgUrl" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tanchuang',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            src: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png'
        }
    },
    created() {
        console.log('stype-----', this.data.immageNavList)
    }
}
</script>

<style lang="scss" scoped>
.box {
    width: 80px;
    .content {
        width: 45px;
        height: 48px;
        background: #fff;
        border-radius: 50%;
        margin-bottom: 10px;
        padding: 12px;
        box-shadow: rgb(204, 204, 204) 0px 0px 10px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
