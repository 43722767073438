<template>
  <div>
    <!-- 内容 -->
    <div
      :style="{
        margin:
          data.marT +
          'px ' +
          data.marR +
          'px ' +
          data.marB +
          'px ' +
          data.marL +
          'px'
      }"
    >
      <!-- 内容 -->
      <div
        class="warp"
        :style="{
          borderRadius: data.borRadius + 'px',
          backgroundColor: data.contentStyle.bgColor
        }"
      >
        <div class="fontS16c3 fontWeight7 marB5">商品详情</div>
        <el-image
          src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/83afc4a2d8084dcc8078c5bcad43a66a.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shangpinxiangqing',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//内容
.warp {
  padding: 15px 10px;
}
</style>
