<template>
  <div>
    <!-- 内容 -->
    <div
      :style="{
        margin:
          data.marT +
          'px ' +
          data.marR +
          'px ' +
          data.marB +
          'px ' +
          data.marL +
          'px'
      }"
    >
      <!-- 内容 -->
      <div
        class="warp"
        :style="{
          borderRadius: data.borRadius + 'px',
          backgroundColor: data.contentStyle.bgColor
        }"
      >
        <!-- 评价条数 -->
        <div class="x-bc marB15">
          <!-- 左对齐 -->
          <div>
            <span
              :style="{
                color: data.contentStyle.titleColor
              }"
              class="fontWeight7 fontS15"
              >评价
            </span>
            <span
              :style="{
                color: data.contentStyle.titleColor
              }"
              v-show="data.contentStyle.isNumberEvaluations"
              >(10万+)</span
            >
          </div>
          <!-- 右对齐 -->
          <div>
            <span v-show="data.contentStyle.isReadMore" class="annotateGrey"
              >更多</span
            >
            <i
              v-show="data.contentStyle.isReadMore"
              class="annotateGrey el-icon-arrow-right"
            ></i>
          </div>
        </div>
        <!-- 用户 -->
        <div class="x-b marB10">
          <!-- 左边作者 -->
          <div class="x-f">
            <el-image
              class="marR5"
              style="width: 40px; height: 40px; border-radius: 50%"
              src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg"
            />
            <div>
              <div class="author">
                <span>烟雨江南</span>
                <span v-show="data.contentStyle.isVipLevel" class="vipSign"
                  >会员</span
                >
              </div>
              <div v-show="data.contentStyle.isEvaluationLeve">
                <span class="iconfont icon-shoucang" />
                <span class="iconfont icon-shoucang" />
                <span class="iconfont icon-shoucang" />
                <span class="iconfont icon-shoucang" />
                <span class="iconfont icon-shoucang1" />
              </div>
            </div>
          </div>
          <!-- 右边时间 -->
          <span
            v-show="data.contentStyle.isTimeEvaluations"
            class="annotateGrey"
            >2018-06-18</span
          >
        </div>
        <!-- 评价 -->
        <div class="omitTwo fontS12c3 marB10" style="width: 100%">
          我最近在一家蛋糕店尝试了他们的蛋糕，真的非常好吃！蛋糕的质地非常细腻，入口即化，而且甜度非常适中，不会太甜也不会太淡，非常好吃。
        </div>
        <!-- 图片 -->
        <div class="marB5" v-show="data.contentStyle.isImgEvaluations">
          <el-image
            class="marR5"
            style="border-radius: 10px"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/0da460dff13a40e98fca93f17e3b15d9.png"
          />
          <el-image
            class="marR5"
            style="border-radius: 10px"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/a768df0265044d0b9469501bf900271d.png"
          />
          <el-image
            style="border-radius: 10px"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/83dde722d40e4c68aeadf19fda72059c.png"
          />
        </div>
        <div
          class="fontS12c3 annotateGrey"
          v-show="data.contentStyle.isGoodsSpece"
        >
          约8英寸（850g）
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shangpinpingjia',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//内容
.warp {
  padding: 15px 10px;
  //作者
  .author {
    height: 20px;
    //vip
    .vipSign {
      margin-left: 4px;
      display: inline-block;
      height: 16px;
      line-height: 16px;
      padding: 0 3px;
      border-radius: 10px;
      color: #f2dcac;
      background: #31312d;
      font-size: 12px;
    }
  }
}
::v-deep .icon-xingxing2 {
  background-color: #fe5a34;
}
//选中
.icon-shoucang {
  color: #fe5a34;
}
// 未选中
.icon-shoucang1 {
  color: #bababa;
}
</style>
