<template>
    <!--  :style="style" -->
    <div :style="{paddingTop: `${data.style.pagePaddingTop? data.style.pagePaddingTop : '0'}px`, paddingRight: `${data.style.pagePaddingRight? data.style.pagePaddingRight : '0'}px`, paddingBottom: `${data.style.pagePaddingBottom? data.style.pagePaddingBottom : '0'}px`, paddingLeft: `${data.style.pagePaddingLeft? data.style.pagePaddingLeft : '0'}px`}">
        <video :src="data.style.videoUrl" width="100%"
               :style="{borderRadius: data.style.chamfer == 'circle' ? '10px' : ''}"
               :controls="controls" :autoplay="data.style.voluntarilyPlay" :poster="data.style.coverImg" preload>
            your browser does not support the video tag
        </video>
    </div>
</template>

<script>
export default {
    name: 'videoTmp',
    props:{
        data:{
            type: Object,
            default: {}
        }
    },
    data(){
        return{
            // data: [],
        }
    },

    created() {
        // this.data = this.$store.state.template.tempData
        console.log('视频组件', this.data.style.videoUrl)
    },
    computed:{
        controls(){
            return this.data.style.progressBar
        },
        // style(){
        //     console.log('视频组件2', this.data)
        //     let { style } = this.data
        //     console.log('视频组件3', style)
        //     let _style = {}
        //     _style.paddingLeft = `${style.pagebackGauge}px`
        //     _style.paddingRight = `${style.pagebackGauge}px`
        // }
    }
}
</script>

<style scoped>

</style>
