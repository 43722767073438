<template>
    <div class="gonggao x-f" style="height: 40px;padding: 0 10px" :style="{background: data.style.bgColor}">
        <iconpark-icon name="speaker-one" color="#ed6a0c" size="18px"></iconpark-icon>
        <span style="margin-left: 10px;" :style="{color: data.style.textColor}">{{data.title}}</span>
    </div>
</template>

<script>
export default {
    name: 'gonggao',
    props:{
        data:{
            type: Object,
            default: {}
        }
    }
}
</script>

<style lang="scss" scoped>
.gonggao{

}
</style>
