var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "coupons" }, [
    _vm.data.style.curListStyleIndex == 0
      ? _c("div", { staticClass: "coupon-list padd10" }, [
          _c(
            "div",
            { staticStyle: { width: "100%", "box-sizing": "border-box" } },
            _vm._l(
              _vm.data.couponList.length
                ? _vm.data.couponList
                : _vm.data.temporaryCouponList,
              function (item, index) {
                return _c("CouponItem", {
                  key: index,
                  staticClass: "marB10",
                  staticStyle: { width: "100%" },
                  attrs: { data: item, styleObj: _vm.data.style },
                })
              }
            ),
            1
          ),
        ])
      : _vm._e(),
    _vm.data.style.curListStyleIndex == 1
      ? _c("div", { staticClass: "padd10 x-w x-bc" }, [
          _c(
            "div",
            {
              staticClass: "x-w x-bc",
              staticStyle: { width: "100%", "box-sizing": "border-box" },
            },
            _vm._l(
              _vm.data.couponList.length
                ? _vm.data.couponList
                : _vm.data.temporaryCouponList,
              function (item, index) {
                return _c("CouponItem", {
                  key: index,
                  staticClass: "marB10",
                  staticStyle: { width: "calc((100% - 20px) / 2)" },
                  attrs: { data: item, styleObj: _vm.data.style },
                })
              }
            ),
            1
          ),
        ])
      : _vm._e(),
    _vm.data.style.curListStyleIndex == 2
      ? _c("div", { staticClass: "padd10 x-w" }, [
          _c(
            "div",
            { staticClass: "x-w x-bc", staticStyle: { width: "100%" } },
            _vm._l(
              _vm.data.couponList.length
                ? _vm.data.couponList
                : _vm.data.temporaryCouponList,
              function (item, index) {
                return _c("CouponItem", {
                  key: index,
                  class: {
                    marB10: _vm.data.couponList.length
                      ? _vm.data.couponList.length
                      : _vm.data.temporaryCouponList.length > 3,
                  },
                  staticStyle: { width: "calc((100% - 20px) / 3)" },
                  attrs: { data: item, styleObj: _vm.data.style },
                })
              }
            ),
            1
          ),
        ])
      : _vm._e(),
    _vm.data.style.curListStyleIndex == 3
      ? _c(
          "div",
          { staticClass: "swipe" },
          [
            _c("el-scrollbar", [
              _c(
                "div",
                { staticClass: "x-f padd10", staticStyle: { width: "100%" } },
                _vm._l(
                  _vm.data.couponList.length
                    ? _vm.data.couponList
                    : _vm.data.temporaryCouponList,
                  function (item, index) {
                    return _c("CouponItem", {
                      key: index,
                      staticClass: "marR10",
                      staticStyle: { "min-width": "calc((100% - 30px) / 3)" },
                      style: ["width: calc(100% -30px) / 3"],
                      attrs: { data: item, styleObj: _vm.data.style },
                    })
                  }
                ),
                1
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }