<template>
    <div :style="{
        margin:
            data.marT +
            'px ' +
            data.marR +
            'px ' +
            data.marB +
            'px ' +
            data.marL +
            'px',
        borderRadius: data.borRadius + 'px'
    }">
        <div class="sousuo" style="padding: 5px 10px" :style="{ background: data.style.bgColor }">
            <div :class="data.style.textPoint" :style="{
                height: `${data.style.frameworkHeigth}px`, background: data.style.framworkColor,
                borderRadius: `${data.style.frameworkStyle}px`, color: data.style.textColor
            }" style="padding: 0 15px">
                <div class="x-f">
                    <iconpark-icon name="sousuo" size="16px"></iconpark-icon>
                    <span style="margin-left: 10px;" :style="{ color: data.style.textColor }">搜索商品</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sousuo',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
}
</script>

<style scoped>
.sousuo {}
</style>
