import { render, staticRenderFns } from "./wenben.vue?vue&type=template&id=3cff837a&scoped=true"
import script from "./wenben.vue?vue&type=script&lang=js"
export * from "./wenben.vue?vue&type=script&lang=js"
import style0 from "./wenben.vue?vue&type=style&index=0&id=3cff837a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cff837a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-pre/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3cff837a')) {
      api.createRecord('3cff837a', component.options)
    } else {
      api.reload('3cff837a', component.options)
    }
    module.hot.accept("./wenben.vue?vue&type=template&id=3cff837a&scoped=true", function () {
      api.rerender('3cff837a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/O2OMall/config/template/decoratePage/components/conterTemplate/wenben.vue"
export default component.exports