<template>
  <div>
    <!-- 内容 -->
    <div
      :style="{
        margin:
          data.marT +
          'px ' +
          data.marR +
          'px ' +
          data.marB +
          'px ' +
          data.marL +
          'px'
      }"
    >
      <!-- 内容 -->
      <div
        class="warp x-bc"
        :class="data.titlePosition == 'left'? 'x-bc' : 'x-c'"
        :style="{
          borderRadius: data.borRadius + 'px',
          backgroundColor:
            data.style.bgColorType === 'bgc' ? data.style.bgColor : ''
        }"
      >
        <div
          :class="
            data.isAssistantEnable && data.assistantSetType === 'leftRight'
              ? 'xF'
              : 'yStart'
          "
        >
          <div class="x-f">
            <iconParkALi
              v-show="data.titleStyle.titleType === 'icon'"
              class="marR3"
              :name="data.titleIcon ? data.titleIcon : 'icon-morentubiao'"
              size="18px"
              :color="data.titleStyle.textColor"
            />
            <span
              class="fontWeight7"
              :style="{
                color: data.titleStyle.textColor,
                fontSize: data.titleStyle.fontSize + 'px',
                borderBottom:
                  data.titleStyle.titleType === 'optionsBar'
                    ? '4px' + ' solid' + data.titleStyle.underlineColor
                    : ''
              }"
              >{{ data.title }}</span
            >
          </div>

          <span
            v-show="data.isAssistantEnable"
            :style="{
              color: data.assistantStyle.textColor,
              fontSize: data.assistantStyle.fontSize + 'px',
              marginLeft: data.assistantSetType === 'leftRight' ? '5px' : '',
              marginTop: data.assistantSetType === 'topBottom' ? '5px' : ''
            }"
          >
            {{ data.assistantTitle }}
          </span>
        </div>
        <div :style="{
          position: 'absolute',
          right: '15px'
        }">
          <div v-show="data.isSeeMore" class="x-f">
            <span
              :style="{
                fontSize: data.seeMoreStyle.fontSize + 'px',
                color: data.seeMoreStyle.textColor
              }"
              >{{ data.seeMoreTitle }}</span
            >
            <iconParkALi
              :name="data.seeMoreIcon"
              size="18px"
              :color="data.seeMoreStyle.textColor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'biaotilan',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//内容
.warp {
  padding: 15px;
  position: relative;
  /* x水平排列*/
  .xF {
    display: flex;
    align-items: center;
  }
  /*y竖直靠左*/
  .yStart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
