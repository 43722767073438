var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-carousel",
        {
          attrs: {
            autoplay: false,
            trigger: "click",
            "indicator-position": _vm.indicator,
            height: "400px",
          },
        },
        _vm._l(_vm.urlList, function (item, index) {
          return _c("el-carousel-item", { key: item }, [
            _c(
              "div",
              { staticClass: "carouselItem" },
              [
                _c("el-image", { attrs: { src: item } }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.indicator == "none",
                        expression: "indicator == 'none'",
                      },
                    ],
                    staticClass: "carouselIndicator",
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(index + 1) +
                          "/" +
                          _vm._s(_vm.urlList.length)
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }