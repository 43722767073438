var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "x-bc",
        staticStyle: { padding: "10px" },
        style: { background: _vm.data.style.bgColor },
      },
      [
        _c(
          "div",
          { staticClass: "x-f" },
          [
            _c("el-image", {
              staticStyle: { width: "60px", height: "60px" },
              attrs: { src: _vm.data.style.imgUrl },
            }),
            _c("div", { staticClass: "y-start marL10" }, [
              _c(
                "span",
                {
                  staticClass: "marB10 omit",
                  staticStyle: {
                    "font-size": "14px",
                    "font-weight": "550",
                    width: "200px",
                  },
                },
                [_vm._v(_vm._s(_vm.data.style.title))]
              ),
              _c(
                "span",
                {
                  staticClass: "omit",
                  staticStyle: {
                    "font-size": "12px",
                    color: "#bababa",
                    width: "200px",
                  },
                },
                [_vm._v(_vm._s(_vm.data.style.describe))]
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              padding: "5px",
              background: "#ff0000",
              color: "#FFFFFF",
              "font-size": "12px",
              "border-radius": "3px",
            },
          },
          [_vm._v(_vm._s(_vm.data.style.btnName))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }