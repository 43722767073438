<template>
  <div
    :style="{
      backgroundColor:
        data.style.bgColorType === 'bgc' ? data.style.bgColor : '',
      backgroundImage:
        data.style.bgColorType === 'img' ? `url(${data.style.bgImgUrl})` : '',
      backgroundSize:
        data.style.bgColorType === 'img' ? `100% 100%` : ''
    }"
  >
    <!-- 头部 -->
    <div class="top x-bc">
      <div class="x-f">
        <el-image
          class="marR10"
          style="width: 49px; height: 49px; border-radius: 50%"
          src="https://v5.100cms.com.cn/static/dist/shop/renren_wap/decorate/avatar_mobile.png "
        />
        <div>
          <!-- 登录 -->
          <div class="x-f">
            <span
              class="fontS16 fontWeight7 marR5"
              :style="{ color: data.style.nameColor }"
              >登录/注册</span
            >
            <iconParkALi
              name="icon-jichu03"
              size="18px"
              :color="data.style.nameColor"
            />
          </div>
        </div>
      </div>
      <div
        :style="{ backgroundColor: data.style.vipLevelBgColor }"
        class="dianpu"
      >
        <span class="fontS14" :style="{ color: data.style.vipLevelIconColor }"
          >会员等级</span
        >
        <iconParkALi
          :name="data.vipLevelIcon"
          size="18px"
          :color="data.style.vipLevelIconColor"
          class="marL5"
        />
      </div>
    </div>
    <!-- 财务 -->
    <div class="btns" v-show="data.FinancialBtnList.length != 0">
      <div
        v-for="(item, index) in data.FinancialBtnList"
        :key="index"
        :style="{ borderColor: data.style.financeDividerColor }"
        class="btn"
      >
        <p class="num" :style="{ color: data.style.financeNumColor }">0</p>
        <p class="content" :style="{ color: data.style.financeTitleColor }">
          {{ item }}
        </p>
        <span
          v-show="data.FinancialBtnList.length != index + 1"
          class="line"
          :style="{ borderColor: data.style.financeDividerColor }"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'huiyuanxinxi',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//头部
.top {
  padding: 16px 12px 24px;
  //等级
  .level {
    margin-top: 4px;
    background: #31312d;
    border-radius: 12px;
    text-align: center;
    display: inline-block;
    color: #f2dcac;
    padding: 2px 5px 0 6px;
    //等级名称
    .levelname {
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  //店铺
  .dianpu {
    padding: 0 12px;
    height: 27px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 19px;
    margin: auto 0;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 27px;
  }
}
//财务
.btns {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  z-index: 1;
  height: 58px;
  padding: 8px 0 8px;
  font-family: PingFang SC;
  font-style: normal;
  //按钮
  .btn {
    position: relative;
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0;
    justify-content: space-between;
    padding-top: -4px;
    //原生属性
    p {
      padding: 0;
      margin: 0;
    }
    //数字
    .num {
      height: 25px;
      line-height: 25px;
      font-weight: bold;
      font-size: 16px;
    }
    //内容
    .content {
      font-size: 12px;
      height: 17px;
      line-height: 17px;
      margin: 0 auto 0;
    }
    //分割线
    .line {
      position: absolute;
      right: 0;
      top: 50%;
      height: 20px;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      border-right: 0.5px solid #fff;
    }
  }
}
</style>
