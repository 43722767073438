<template>
  <div
    :style="{
      backgroundColor: data.contentStyle.bgColor,
      borderRadius: data.contentStyle.navShape === 'suspension' ? '20px' : '0',
    }"
    class="padT5 padB5 x-f"
  >
    <!-- 内容 -->
    <div v-for="(item, index) in filteredArr" :key="index" class="marL20">
      <div
        class="y-c"
        :style="item.textInputList[0].value === '购物车' ? { position: 'relative' } : {}"
      >
        <iconParkALi
          :name="
            item.titleIconList[0].icon ? item.titleIconList[0].icon : 'icon-morentubiao'
          "
          size="18px"
          :color="item.titleIconList[0].icon ? data.contentStyle.iconColor : ''"
        />
        <span
          class="fontSW14 iconTitle"
          :style="{
            color: data.contentStyle.textColor,
          }"
          >{{ item.textInputList[0].value }}
        </span>
        <span
          v-if="item.textInputList[0].value === '购物车'"
          class="badge"
          :style="{ backgroundColor: data.contentStyle.angleMarkColor }"
        >
          1
        </span>
      </div>
    </div>
    <!-- 右边 -->
    <div class="right">
      <span
        class="fontSW14 leftBtn"
        :style="{
          backgroundColor: data.contentStyle.leftBtnColor,
        }"
        >加入购物车</span
      >
      <span
        class="fontSW14 rightBtn"
        :style="{
          backgroundColor: data.contentStyle.rightBtnColor,
        }"
        >立即购买</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "dibudaohang",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  computed: {
    filteredArr() {
      return this.data.iconStyleList.filter((item) => item.isEnable);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
//左边的角标
.badge {
  position: absolute;
  background-color: #ff3c29;
  top: -3px;
  right: 0;
  width: 20px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding-left: 6px;
}
//右边内容
.right {
  flex: 1;
  height: 38px;
  background-color: #fff;
  border-radius: 20px;
  margin: 0 10px;
  //操作 左
  .leftBtn {
    text-align: center;
    display: inline-block;
    width: 50%;
    height: 100%;
    padding-top: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  //操作 右
  .rightBtn {
    text-align: center;
    display: inline-block;
    width: 50%;
    height: 100%;
    padding-top: 8px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
</style>
