<template>
  <div>
    <!-- 内容 -->
    <div
      :style="{
        margin:
          data.marT +
          'px ' +
          data.marR +
          'px ' +
          data.marB +
          'px ' +
          data.marL +
          'px'
      }"
    >
      <!-- 内容 -->
      <div
        class="warp x-bc"
        :style="{
          borderTopLeftRadius: data.borTopRadius + 'px',
          borderTopRightRadius: data.borTopRadius + 'px',
          borderBottomLeftRadius: data.borBottomRadius + 'px',
          borderBottomRightRadius: data.borBottomRadius + 'px',
          backgroundColor:
            data.style.bgColorType === 'bgc' ? data.style.bgColor : ''
        }"
      >
        <div
          :class="
            data.isAssistantEnable && data.assistantSetType === 'leftRight'
              ? 'xF'
              : 'yStart'
          "
        >
          <div class="x-f">
            <el-image
              class="marR5"
              style="width: 28px; height: 28px"
              :src="data.titleUrl"
            />
            <span
              class="fontWeight7"
              :style="{
                color: data.titleStyle.textColor,
                fontSize: data.titleStyle.fontSize + 'px',
                borderBottom:
                  data.titleStyle.titleType === 'optionsBar'
                    ? '4px' + ' solid' + data.titleStyle.underlineColor
                    : ''
              }"
              >{{ data.title }}</span
            >
          </div>
        </div>
        <div>
          <div class="x-f">
            <span
              class="marR5"
              :style="{
                color: data.assistantStyle.textColor,
                fontSize: data.assistantStyle.fontSize + 'px'
              }"
            >
              {{ data.assistantTitle }}到期
            </span>
            <span
              :style="{
                fontSize: data.seeMoreStyle.fontSize + 'px',
                color: data.seeMoreStyle.textColor
              }"
              >{{ data.seeMoreTitle }}</span
            >
            <iconParkALi
              :name="data.seeMoreIcon"
              size="18px"
              :color="data.seeMoreStyle.iconColor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plusVip',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//内容
.warp {
  padding: 10px;
  /* x水平排列*/
  .xF {
    display: flex;
    align-items: center;
  }
  /*y竖直靠左*/
  .yStart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
