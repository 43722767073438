<template>
    <div>
        <div class="fengefuzhu" :style="{height: data.style.auxiliaryType == 'wuxian' ? `${data.style.borderHeight}px` : '',
            paddingLeft: `${data.style.backGauge}px`, paddingRight: `${data.style.backGauge}px`}"
        >
            <hr v-if="data.style.auxiliaryType == 'youxian'" :style="{borderTop:`1px ${data.style.lineStyle} ${data.style.color}`}"/>
        </div>

    </div>
</template>

<script>
export default {
    name: 'fengefuzhu',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    computed:{

    },
    created() {
        // console.log('分割线：', this.data.style.lineStyle)
    }
}
</script>

<style lang="scss" scoped>
.fengefuzhu {
    width: 100%;

    // 实线
    .hr-solid {
        border: 1px;
        border-top: 1px solid #d0d0d5;
    }
    // 点线
    .hr-dotted {
        border: 1px;
        border-top: 1px dotted #a2a9b6;
    }
    // 虚线
    .hr-dashed {
        border: 1px;
        border-top: 1px dashed #a2a9b6;
    }

}

</style>
