<template>
  <div>
    <!-- 内容 -->
    <div
      :style="{
        margin:
          data.marT + 'px ' + data.marR + 'px ' + data.marB + 'px ' + data.marL + 'px',
      }"
    >
      <!-- 内容 -->
      <div
        class="warp"
        :style="{
          borderRadius: data.borRadius + 'px',
          backgroundColor: data.contentStyle.bgColor,
        }"
      >
        <!-- 价格、销量 -->
        <div class="x-bc">
          <!-- 左边 -->
          <div class="x-f">
            <span
              class="fontS16 marR5"
              :style="{
                color: data.contentStyle.priceColor,
              }"
              >￥166</span
            >
            <span
              class="fontS14 marT5 marR5"
              :style="{
                color: data.contentStyle.priceColor,
              }"
              v-show="data.isUnit"
              >/盒</span
            >
            <span class="asterisk pricebgc">会员价</span>
          </div>
          <!-- 右边 -->
          <div>
            <span
              class="marR10"
              :style="{
                color: data.contentStyle.salesVolumeColor,
              }"
              >库存999</span
            >
            <span
              :style="{
                color: data.contentStyle.salesVolumeColor,
              }"
              >销量666</span
            >
          </div>
        </div>
        <!-- 原价 -->
        <div class="originalPrice">￥ 334</div>
        <!-- 营销信息 -->
        <div class="x-bc marB15" v-show="data.isMarketing">
          <!-- 券 -->
          <div>
            <span
              :style="{
                color: data.marketingStyle.textColor,
                backgroundColor: data.marketingStyle.bgColor,
              }"
              class="pricebgc marR5"
              >券 | 满10减2</span
            >
            <span
              :style="{
                color: data.marketingStyle.textColor,
                backgroundColor: data.marketingStyle.bgColor,
              }"
              class="pricebgc marR5"
              >满100减30</span
            >
            <span
              :style="{
                color: data.marketingStyle.textColor,
                backgroundColor: data.marketingStyle.bgColor,
              }"
              class="pricebgc marR5"
              >满200包邮</span
            >
          </div>
          <!-- 更多 -->
          <div>
            <span
              :style="{
                color: data.marketingStyle.textColor,
              }"
              >更多</span
            >
            <i
              :style="{
                color: data.marketingStyle.textColor,
              }"
              class="el-icon-arrow-right"
            ></i>
          </div>
        </div>
        <!-- 标题 -->
        <div
          class="goodsTitle marB10"
          :style="{
            color: data.contentStyle.titleColor,
          }"
        >
          夏日清爽水果蛋糕女朋友生日蛋糕下午茶甜点生日聚会同城配送网红创意6寸
        </div>
        <!-- 介绍 -->
        <div class="x-bc">
          <span
            :style="{
              color: data.contentStyle.subtitleColor,
            }"
          >
            水果为主要材料，口感清新、爽口，适合夏季食用。</span
          >
          <i
            :style="{
              color: data.contentStyle.subtitleColor,
            }"
            class="el-icon-arrow-down"
          ></i>
        </div>
        <!-- 服务 -->
        <div class="service x-f" v-show="data.isService">
          <div class="x-f marR10">
            <iconpark-icon
              :color="data.serviceStyle.labelColor"
              :name="data.serviceStyle.shape == 'duihao' ? 'check-one' : 'dot'"
              class="marR5"
            />

            <span
              :style="{
                color: data.serviceStyle.textColor,
              }"
              >门店自提</span
            >
          </div>
          <div class="x-f marR10">
            <iconpark-icon
              :color="data.serviceStyle.labelColor"
              :name="data.serviceStyle.shape == 'duihao' ? 'check-one' : 'dot'"
              class="marR5"
            />
            <span
              :style="{
                color: data.serviceStyle.textColor,
              }"
              >同城配送</span
            >
          </div>
          <div class="x-f">
            <iconpark-icon
              :color="data.serviceStyle.labelColor"
              :name="data.serviceStyle.shape == 'duihao' ? 'check-one' : 'dot'"
              class="marR5"
            />
            <span
              :style="{
                color: data.serviceStyle.textColor,
              }"
              >快递配送</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "shangpinxinxi",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
//内容
.warp {
  padding: 15px 10px;
}

//价格背景
.pricebgc {
  padding: 3px;
  border-radius: 3px;
  background-color: #fff3f5;
}
//原价
.originalPrice {
  margin: 5px 0 8px 2px;
  color: #969696;
  text-decoration: line-through;
}
//标题
.goodsTitle {
  font-weight: 700;
  // word-wrap: break-word;
}
//服务
.service {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid rgb(230, 231, 235);
}
</style>
