<template>
    <div>
        <div v-if="styleObj.curListStyleIndex > 0" class="item y-c padd10"
             :class="cardList[styleObj.curCardStyleIndex].style"
             :style="[
                {color: styleObj.textColor},
                {backgroundColor: styleObj.couponColor},
                {border: styleObj.curListStyleIndex == 3 ? `5px solid ${styleObj.couponColor}`: ``},
                {borderRadius: styleObj.curCardStyleIndex == 3 ? '5px' : ''}
            ]"
        >
            <div class="y-c" style="width: 100%" :style="style2">
                <div class="x-f">
                    <span class="fontS25">{{ data.parValue }}</span>
                    <span>元</span>
                </div>
                <div class="x-c" :class="{omit : styleObj.curListStyleIndex > 0}" style="width: 100%;box-sizing: border-box">
                    {{ data.couponDesc }}
                </div>
            </div>

        </div>
        <div v-else class="x-bc item marB10"
             :class="[
                cardList[styleObj.curCardStyleIndex].style,
                {'border': style.curCardStyleIndex == 4}]"
             :style="[
                {color: styleObj.textColor},
                {backgroundColor: `${styleObj.couponColor}`},
                {border: styleObj.curListStyleIndex == 3 ? `5px solid ${styleObj.couponColor}`: ``},
                {border: styleObj.curCardStyleIndex == 4 ? '5px' : ''},
                {borderRadius: styleObj.curCardStyleIndex == 3 ? '5px' : ''}
            ]"
        >
            <div class="x-f" style="width: 70%">
                <div class="x-f">
                    <span class="fontS25">{{ data.parValue }}</span>
                    <span>元</span>
                </div>
                <div class="y-start marL10">
                    <div>{{ data.couponCaseName }}</div>
                    <div class="x-c" :class="{omit : styleObj.curListStyleIndex > 0}" style="width: 100%;box-sizing: border-box">
                        {{ data.couponDesc }}
                    </div>
                </div>
            </div>
            <div class="x-c" style="width: 30%">
                领取
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'couponItem',
    props: {
        data: {
            type: Object,
            default: {}
        },
        styleObj: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            cardList: [
                { id: 1, style: 'content2', text: '样式一' },
                { id: 2, style: 'content3', text: '样式二' },
                { id: 3, style: 'content4', text: '样式三' },
                { id: 4, style: 'content5', text: '样式四' },
                { id: 5, style: 'content6', text: '样式五' },
                { id: 6, style: 'content7', text: '样式六' },
                { id: 7, style: 'content8', text: '样式七' },
                { id: 8, style: 'content9', text: '样式八' }
            ]
        }
    },

    computed: {
        style() {
            let style = this.styleObj
            let _style = {}
            console.log('初始化颜色：', this.styleObj.textColor)
            console.log('初始化背景颜色：', this.styleObj.couponColor)
            // _style.color = `${style.textColor}`
            // _style.background = `${style.couponColor}`
            // if (style.curListStyleIndex == 3) {
                // _style.borderWidth = `5px solid ${style.couponColor}`
            // }
            // if (style.curCardStyleIndex == 4) {
            //     _style.border = `3px solid ${style.couponColor}`
            //     _style.background = `#fdf1ed`
            //     _style.color = `${style.couponColor}`
            //     _style.padding = `5px`
            // }

            if (style.curCardStyleIndex == 4) {
                _style.border = `3px solid ${style.couponColor}`
                _style.background = `#fdf1ed`
                _style.color = `${style.couponColor}`
                _style.padding = `5px`
            }
            if (style.curCardStyleIndex == 5) {
                _style.borderRadius = '5px'
            }


            return _style

        },
        style2(){
            let style = this.styleObj
            let _style = {}
            if (style.curCardStyleIndex == 4) {
                _style.border = `1px solid ${style.couponColor}`
            }

            return _style
        }
    },
    created() {
        console.log('========颜色===========', this.styleObj.couponColor)
    }
}
</script>

<style lang="scss" scoped>

.item {
    height: 80px;
    padding: 5px;
}

.content1 {
    /**
        第一个radial-gradient ： 中间分割的圆点
     */
    -webkit-mask: radial-gradient(circle at 70%, red 1px, #0000 0) 70% 50% / 100% 5px,
    radial-gradient(circle at 10px 10px, #0000 5px, red 0) -20px -10px / 73%,;
    -webkit-mask-composite: destination-out;
    mask-composite: subtract; /*Firefox*/
}

.content2 {
    padding: 10px 15px 10px 20px;
    -webkit-mask: radial-gradient(circle at 10px, transparent 3px, red 0) -10px / 100% 10px;
}

.content3 {
    padding: 10px 15px 10px 20px;
    -webkit-mask: radial-gradient(circle at 20px 20px, #0000 10px, red 0);
    -webkit-mask-position: -20px -20px;
}

.content4 {
    border-width: 5px 20px 5px 5px;
    -webkit-mask: radial-gradient(circle at 100%, #0000 10px, red 0);
    border-radius: 5px;
}

.border{
    border: 3px solid;
}
</style>
