var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        margin:
          _vm.data.marT +
          "px " +
          _vm.data.marR +
          "px " +
          _vm.data.marB +
          "px " +
          _vm.data.marL +
          "px",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "warp x-w",
          style: {
            borderRadius: _vm.data.borRadius + "px",
            backgroundColor: _vm.data.style.bgColor,
          },
        },
        _vm._l(_vm.data.contentList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "iconItem x-fc",
              style: {
                width:
                  _vm.data.lineNum === 3
                    ? "33.33333%"
                    : _vm.data.lineNum === 4
                    ? "25%"
                    : "20%",
                boxSizing: "border-box",
              },
            },
            [
              _c(
                "div",
                { staticClass: "y-c" },
                [
                  _c("iconParkALi", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.btnType === "icon",
                        expression: "data.btnType === 'icon'",
                      },
                    ],
                    attrs: {
                      color: item.icon ? _vm.data.style.iconColor : "",
                      name: item.icon ? item.icon : "icon-morentubiao",
                      size: "28px",
                    },
                  }),
                  _c("el-image", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.btnType === "img",
                        expression: "data.btnType === 'img'",
                      },
                    ],
                    staticStyle: { width: "28px", height: "28px" },
                    attrs: {
                      src: item.imgUrl
                        ? item.imgUrl
                        : "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-13/bbae70119c51444cb5b67a529186025b.png",
                    },
                  }),
                  _c(
                    "p",
                    {
                      style: {
                        color: _vm.data.style.textColor,
                      },
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }