var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        backgroundColor:
          _vm.data.style.bgColorType === "bgc" ? _vm.data.style.bgColor : "",
        backgroundImage:
          _vm.data.style.bgColorType === "img"
            ? "url(" + _vm.data.style.bgImgUrl + ")"
            : "",
        backgroundSize: _vm.data.style.bgColorType === "img" ? "100% 100%" : "",
      },
    },
    [
      _c("div", { staticClass: "top x-bc" }, [
        _c(
          "div",
          { staticClass: "x-f" },
          [
            _c("el-image", {
              staticClass: "marR10",
              staticStyle: {
                width: "49px",
                height: "49px",
                "border-radius": "50%",
              },
              attrs: {
                src: "https://v5.100cms.com.cn/static/dist/shop/renren_wap/decorate/avatar_mobile.png ",
              },
            }),
            _c("div", [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "fontS16 fontWeight7 marR5",
                      style: { color: _vm.data.style.nameColor },
                    },
                    [_vm._v("登录/注册")]
                  ),
                  _c("iconParkALi", {
                    attrs: {
                      name: "icon-jichu03",
                      size: "18px",
                      color: _vm.data.style.nameColor,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "dianpu",
            style: { backgroundColor: _vm.data.style.vipLevelBgColor },
          },
          [
            _c(
              "span",
              {
                staticClass: "fontS14",
                style: { color: _vm.data.style.vipLevelIconColor },
              },
              [_vm._v("会员等级")]
            ),
            _c("iconParkALi", {
              staticClass: "marL5",
              attrs: {
                name: _vm.data.vipLevelIcon,
                size: "18px",
                color: _vm.data.style.vipLevelIconColor,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.data.FinancialBtnList.length != 0,
              expression: "data.FinancialBtnList.length != 0",
            },
          ],
          staticClass: "btns",
        },
        _vm._l(_vm.data.FinancialBtnList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "btn",
              style: { borderColor: _vm.data.style.financeDividerColor },
            },
            [
              _c(
                "p",
                {
                  staticClass: "num",
                  style: { color: _vm.data.style.financeNumColor },
                },
                [_vm._v("0")]
              ),
              _c(
                "p",
                {
                  staticClass: "content",
                  style: { color: _vm.data.style.financeTitleColor },
                },
                [_vm._v(" " + _vm._s(item) + " ")]
              ),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.data.FinancialBtnList.length != index + 1,
                    expression: "data.FinancialBtnList.length != index + 1",
                  },
                ],
                staticClass: "line",
                style: { borderColor: _vm.data.style.financeDividerColor },
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }