<template>
  <!-- 中间展示商品样式组件 -->
  <div class="goods">
    <div
      class="goods-list"
      :style="{
        paddingLeft: `${data.moreSet.pageBackGauge}px`,
        paddingRight: `${data.moreSet.pageBackGauge}px`,
      }"
    >
      <!-- 一行一个 -->
      <div class="list" v-if="data.curListStyleIndex === 0">
        <GoodsItem
          :style="{
            width: '100%',
            marginTop: `${data.moreSet.goodsBackGauge}px`,
            marginBottom: `${data.moreSet.goodsBackGauge}px`,
          }"
          :overallFlex="true"
          v-for="(item, index) in data.goodList.length < 1
            ? [data.temporaryGoodList[0]]
            : data.goodList"
          :key="index"
          :goodsItem="item"
          infoHeight="155px"
          :curGoodsStyleIndex="data.curGoodsStyleIndex"
          :moreSet="data.moreSet"
          :CardIndex="data.curShoppingCardIndex"
          :CardText="data.btnText"
          :goodsBorderAngle="data.goodsBorderAngle"
        ></GoodsItem>
      </div>
      <!-- 一行两个 -->
      <div class="small x-bc" v-else-if="data.curListStyleIndex === 1">
        <GoodsItem
          :style="{
            width: `calc(50% - ${data.moreSet.goodsBackGauge / 2}px)`,
            marginTop: `${data.moreSet.goodsBackGauge}px`,
          }"
          :photoWidthHeight="data.photoWidthHeight"
          v-for="(item, index) in data.goodList < 1
            ? [data.temporaryGoodList[0], [data.temporaryGoodList[1]]]
            : data.goodList"
          :key="index"
          :goodsItem="item"
          :curGoodsStyleIndex="data.curGoodsStyleIndex"
          :moreSet="data.moreSet"
          :CardIndex="data.curShoppingCardIndex"
          :CardText="data.btnText"
          :goodsRatio="data.moreSet.goodsRatio"
          :goodsBorderAngle="data.goodsBorderAngle"
        ></GoodsItem>
      </div>
      <!-- 一行三个 -->
      <!-- <div class="three x-bc" :class="data.goodList.length == 2? 'x-f' : 'x-bc'" :style="{ -->
      <div class="three" :class="data.goodList.length == 2? 'x-f' : 'x-bc'" v-else-if="data.curListStyleIndex === 2">
        <GoodsItem
          :style="{
            width: `calc(33.33% - ${data.moreSet.goodsBackGauge / 2}px)`,
            marginTop: `${data.moreSet.goodsBackGauge}px`,
            marginRight: `${data.goodList.length == 2? '5px' : 0}`
          }"
          :photoWidthHeight="data.photoWidthHeight"
          v-for="(item, index) in data.goodList< 1? data.temporaryGoodList
            : data.goodList"
          :key="index"
          :goodsItem="item"
          :curGoodsStyleIndex="data.curGoodsStyleIndex"
          :moreSet="data.moreSet"
          :CardIndex="data.curShoppingCardIndex"
          :CardText="data.btnText"
          :goodsRatio="data.moreSet.goodsRatio"
          :goodsBorderAngle="data.goodsBorderAngle"
        ></GoodsItem>
      </div>
      <!-- 大图模式 -->
      <div class="big" v-else-if="data.curListStyleIndex === 3">
        <GoodsItem
          :style="{
            width: `calc(100% - 0px)`,
            marginTop: `${data.moreSet.goodsBackGauge}px`,
          }"
          :photoWidthHeight="data.photoWidthHeight"
          v-for="(item, index) in data.goodList < 1
            ? [data.temporaryGoodList[0]]
            : data.goodList"
          :key="index"
          :goodsItem="item"
          :curGoodsStyleIndex="data.curGoodsStyleIndex"
          :moreSet="data.moreSet"
          :CardIndex="data.curShoppingCardIndex"
          :CardText="data.btnText"
          :goodsRatio="data.moreSet.goodsRatio"
          :goodsBorderAngle="data.goodsBorderAngle"
        ></GoodsItem>
      </div>
      <!-- 横向滑动 -->
      <div class="swipe x-bc" v-else-if="data.curListStyleIndex === 4">
        <el-scrollbar>
          <GoodsItem
            :style="{
              width: `calc(100% - ${data.moreSet.goodsBackGauge}px)`,
              marginRight: `${data.moreSet.goodsBackGauge}px`,
            }"
            :photoWidthHeight="data.photoWidthHeight"
            v-for="(item, index) in data.goodList < 1
              ? data.temporaryGoodList
              : data.goodList"
            :key="index"
            :goodsItem="item"
            :curGoodsStyleIndex="data.curGoodsStyleIndex"
            :moreSet="data.moreSet"
            :CardIndex="data.curShoppingCardIndex"
            :CardText="data.btnText"
            :goodsRatio="data.moreSet.goodsRatio"
            :goodsBorderAngle="data.goodsBorderAngle"
          ></GoodsItem>
        </el-scrollbar>
      </div>
      <!-- 一大两小 -->
      <div class="hybrid" v-else-if="data.curListStyleIndex === 5">
        <div class="one">
          <GoodsItem
            :style="{ width: `100% `, marginTop: `${data.moreSet.goodsBackGauge}px` }"
            :photoWidthHeight="data.photoWidthHeight"
            :curGoodsStyleIndex="data.curGoodsStyleIndex"
            :moreSet="data.moreSet"
            :CardIndex="data.curShoppingCardIndex"
            :CardText="data.btnText"
            :goodsItem="data.singleGood"
            :goodsRatio="data.moreSet.goodsRatio"
            :goodsBorderAngle="data.goodsBorderAngle"
          ></GoodsItem>
        </div>
        <div class="two x-bc">
          <GoodsItem
            :style="{
              width: `calc(50% - ${data.moreSet.goodsBackGauge}px)`,
              marginTop: `${data.moreSet.goodsBackGauge}px`,
            }"
            :photoWidthHeight="data.photoWidthHeightTwo"
            v-for="(item, index) in goods"
            :key="index"
            :goodsItem="item"
            :curGoodsStyleIndex="data.curGoodsStyleIndex"
            :moreSet="data.moreSet"
            :CardIndex="data.curShoppingCardIndex"
            :CardText="data.btnText"
            :goodsRatio="data.moreSet.goodsRatio"
            :goodsBorderAngle="data.goodsBorderAngle"
          ></GoodsItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsItem from "@/views/O2OMall/config/template/decoratePage/components/conterTemplate/components/goodsItem.vue"; // 商品每一项组件
export default {
  name: "ConterGoods",
  components: { GoodsItem },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isThreeMB(list, index) {
        return [list.length - 1, list.length - 2, list.length - 3]
          .slice(0, list.length % 3 || 3)
          .includes(index);
      },
      goods: [],
    };
  },
  watch: {
    data: {
      handler(val) {
        console.log(val);
        if (val.curListStyleIndex === 2 || val.curListStyleIndex === 4) {
          this.data.moreSet.isShowCountDown = false;
        } else {
          this.data.moreSet.isShowCountDown = true;
        }
        if (val.curListStyleIndex === 5) {
          if (val.goodList.length < 1) {
            this.goods = JSON.parse(JSON.stringify(val.temporaryGoodList));
            this.data.singleGood = val.temporaryGoodList[0];
            this.goods = [val.temporaryGoodList[1], val.temporaryGoodList[2]];
          } else {
            this.goods = JSON.parse(JSON.stringify(val.goodList));
            this.data.singleGood = val.goodList[0];
            this.goods = [val.goodList[1], val.goodList[2]];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

//整体组件背景
.goods {
  background-color: #f5f5f5;
}

//一行一个
.list {
  width: 100%;
}

// 一行两个
.small {
  display: flex;
  flex-wrap: wrap;
}

// 一行三个
.three {
  display: flex;
  flex-wrap: wrap;
}

//大图模式
.big {
}

//横向滑动
.swipe {
  //滚动条
  ::v-deep .el-scrollbar__wrap {
    overflow-x: auto;
  }

  ::v-deep .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
    white-space: nowrap;
    display: flex;
  }

  .goodsItem {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30%;
  }
}

// 一大两小
.hybrid {
}
</style>
