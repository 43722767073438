var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goods" }, [
    _c(
      "div",
      {
        staticClass: "goods-list",
        style: {
          paddingLeft: _vm.data.moreSet.pageBackGauge + "px",
          paddingRight: _vm.data.moreSet.pageBackGauge + "px",
        },
      },
      [
        _vm.data.curListStyleIndex === 0
          ? _c(
              "div",
              { staticClass: "list" },
              _vm._l(
                _vm.data.goodList.length < 1
                  ? [_vm.data.temporaryGoodList[0]]
                  : _vm.data.goodList,
                function (item, index) {
                  return _c("GoodsItem", {
                    key: index,
                    style: {
                      width: "100%",
                      marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                      marginBottom: _vm.data.moreSet.goodsBackGauge + "px",
                    },
                    attrs: {
                      overallFlex: true,
                      goodsItem: item,
                      infoHeight: "155px",
                      curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                      moreSet: _vm.data.moreSet,
                      CardIndex: _vm.data.curShoppingCardIndex,
                      CardText: _vm.data.btnText,
                      goodsBorderAngle: _vm.data.goodsBorderAngle,
                    },
                  })
                }
              ),
              1
            )
          : _vm.data.curListStyleIndex === 1
          ? _c(
              "div",
              { staticClass: "small x-bc" },
              _vm._l(
                _vm.data.goodList < 1
                  ? [
                      _vm.data.temporaryGoodList[0],
                      [_vm.data.temporaryGoodList[1]],
                    ]
                  : _vm.data.goodList,
                function (item, index) {
                  return _c("GoodsItem", {
                    key: index,
                    style: {
                      width:
                        "calc(50% - " +
                        _vm.data.moreSet.goodsBackGauge / 2 +
                        "px)",
                      marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                    },
                    attrs: {
                      photoWidthHeight: _vm.data.photoWidthHeight,
                      goodsItem: item,
                      curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                      moreSet: _vm.data.moreSet,
                      CardIndex: _vm.data.curShoppingCardIndex,
                      CardText: _vm.data.btnText,
                      goodsRatio: _vm.data.moreSet.goodsRatio,
                      goodsBorderAngle: _vm.data.goodsBorderAngle,
                    },
                  })
                }
              ),
              1
            )
          : _vm.data.curListStyleIndex === 2
          ? _c(
              "div",
              {
                staticClass: "three",
                class: _vm.data.goodList.length == 2 ? "x-f" : "x-bc",
              },
              _vm._l(
                _vm.data.goodList < 1
                  ? _vm.data.temporaryGoodList
                  : _vm.data.goodList,
                function (item, index) {
                  return _c("GoodsItem", {
                    key: index,
                    style: {
                      width:
                        "calc(33.33% - " +
                        _vm.data.moreSet.goodsBackGauge / 2 +
                        "px)",
                      marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                      marginRight:
                        "" + (_vm.data.goodList.length == 2 ? "5px" : 0),
                    },
                    attrs: {
                      photoWidthHeight: _vm.data.photoWidthHeight,
                      goodsItem: item,
                      curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                      moreSet: _vm.data.moreSet,
                      CardIndex: _vm.data.curShoppingCardIndex,
                      CardText: _vm.data.btnText,
                      goodsRatio: _vm.data.moreSet.goodsRatio,
                      goodsBorderAngle: _vm.data.goodsBorderAngle,
                    },
                  })
                }
              ),
              1
            )
          : _vm.data.curListStyleIndex === 3
          ? _c(
              "div",
              { staticClass: "big" },
              _vm._l(
                _vm.data.goodList < 1
                  ? [_vm.data.temporaryGoodList[0]]
                  : _vm.data.goodList,
                function (item, index) {
                  return _c("GoodsItem", {
                    key: index,
                    style: {
                      width: "calc(100% - 0px)",
                      marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                    },
                    attrs: {
                      photoWidthHeight: _vm.data.photoWidthHeight,
                      goodsItem: item,
                      curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                      moreSet: _vm.data.moreSet,
                      CardIndex: _vm.data.curShoppingCardIndex,
                      CardText: _vm.data.btnText,
                      goodsRatio: _vm.data.moreSet.goodsRatio,
                      goodsBorderAngle: _vm.data.goodsBorderAngle,
                    },
                  })
                }
              ),
              1
            )
          : _vm.data.curListStyleIndex === 4
          ? _c(
              "div",
              { staticClass: "swipe x-bc" },
              [
                _c(
                  "el-scrollbar",
                  _vm._l(
                    _vm.data.goodList < 1
                      ? _vm.data.temporaryGoodList
                      : _vm.data.goodList,
                    function (item, index) {
                      return _c("GoodsItem", {
                        key: index,
                        style: {
                          width:
                            "calc(100% - " +
                            _vm.data.moreSet.goodsBackGauge +
                            "px)",
                          marginRight: _vm.data.moreSet.goodsBackGauge + "px",
                        },
                        attrs: {
                          photoWidthHeight: _vm.data.photoWidthHeight,
                          goodsItem: item,
                          curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                          moreSet: _vm.data.moreSet,
                          CardIndex: _vm.data.curShoppingCardIndex,
                          CardText: _vm.data.btnText,
                          goodsRatio: _vm.data.moreSet.goodsRatio,
                          goodsBorderAngle: _vm.data.goodsBorderAngle,
                        },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            )
          : _vm.data.curListStyleIndex === 5
          ? _c("div", { staticClass: "hybrid" }, [
              _c(
                "div",
                { staticClass: "one" },
                [
                  _c("GoodsItem", {
                    style: {
                      width: "100% ",
                      marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                    },
                    attrs: {
                      photoWidthHeight: _vm.data.photoWidthHeight,
                      curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                      moreSet: _vm.data.moreSet,
                      CardIndex: _vm.data.curShoppingCardIndex,
                      CardText: _vm.data.btnText,
                      goodsItem: _vm.data.singleGood,
                      goodsRatio: _vm.data.moreSet.goodsRatio,
                      goodsBorderAngle: _vm.data.goodsBorderAngle,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "two x-bc" },
                _vm._l(_vm.goods, function (item, index) {
                  return _c("GoodsItem", {
                    key: index,
                    style: {
                      width:
                        "calc(50% - " + _vm.data.moreSet.goodsBackGauge + "px)",
                      marginTop: _vm.data.moreSet.goodsBackGauge + "px",
                    },
                    attrs: {
                      photoWidthHeight: _vm.data.photoWidthHeightTwo,
                      goodsItem: item,
                      curGoodsStyleIndex: _vm.data.curGoodsStyleIndex,
                      moreSet: _vm.data.moreSet,
                      CardIndex: _vm.data.curShoppingCardIndex,
                      CardText: _vm.data.btnText,
                      goodsRatio: _vm.data.moreSet.goodsRatio,
                      goodsBorderAngle: _vm.data.goodsBorderAngle,
                    },
                  })
                }),
                1
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }