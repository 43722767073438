var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: {
          margin:
            _vm.data.marT +
            "px " +
            _vm.data.marR +
            "px " +
            _vm.data.marB +
            "px " +
            _vm.data.marL +
            "px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "warp",
            style: {
              borderRadius: _vm.data.borRadius + "px",
              backgroundColor: _vm.data.contentStyle.bgColor,
            },
          },
          [
            _c("div", { staticClass: "fontS16c3 fontWeight7 marB5" }, [
              _vm._v("商品详情"),
            ]),
            _c("el-image", {
              attrs: {
                src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/83afc4a2d8084dcc8078c5bcad43a66a.png",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }